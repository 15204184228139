<template>
    <div id="service" class="expect-style-area pt-100 pb-70">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">What’s Your Expect</span>
                <h2>We Have Offer For You That You Expect</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="expect-style-card">
                        <h3>Get Mortgage Rate</h3>
                        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="expect-style-card">
                        <h3>Home Buyer’s Guide</h3>
                        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="expect-style-card">
                        <h3>Home Equity</h3>
                        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="expect-style-card">
                        <h3>Refinancing Service</h3>
                        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        <a href="#">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Expect'
}
</script>