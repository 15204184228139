<template>
    <div id="service" class="five-service-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title three-section-title">
                <span class="sub-title"># CHOOSE YOUR SERVICE</span>
                <h2>Nearly We Have Been Trying To Provide Those</h2>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="service-item">
                        <div class="service-img">
                            <img src="../../assets/img/home-five/service1.png" alt="Service">
                        </div>
                        <h3>Auto Dealer Solutions</h3>
                        <p>Lorem ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                Affordable Car Calculator
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                Auto Financing Center
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                Valley All Access Rewards
                            </li>
                        </ul>
                        <a class="cmn-banner-btn" href="#" target="_blank">
                            Get Quote
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                        <a class="service-btn" href="#" target="_blank">
                            Learn More
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="service-item">
                        <div class="service-img">
                            <img src="../../assets/img/home-five/service2.png" alt="Service">
                        </div>
                        <h3>Floor Plan Financing</h3>
                        <p>Ryun ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                Dealership to The Next Level
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                Financing Option For Your Dealership
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                Business Insight Center
                            </li>
                        </ul>
                        <a class="cmn-banner-btn" href="#" target="_blank">
                            Get Quote
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                        <a class="service-btn" href="#" target="_blank">
                            Learn More
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseYourService'
}
</script>