<template>
    <div id="team" class="staff-area pb-70">
        <div class="container">
            <div class="main-section-title">
                <h2>{{ $t('Our-Team') }}</h2>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/PingChen.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3><a href="/staff/ping" target="_blank">{{ $t('StaffName-PingChen')}}</a></h3>
                            <span>{{ $t('StaffTitle-PingChen') }}</span><br />
                            <span><a href="mailto:Ping@pcwlaw.co.nz">Ping@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/DrKey.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3><a href="/staff/drkey" target="_blank">{{ $t('StaffName-DrKeeTee')}}</a></h3>
                            <span>{{ $t('StaffTitle-DrKeeTee') }}</span><br />
                            <span><a href="mailto:key@pcwlaw.co.nz">Key@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/AnnetteWoodroffe.jpg" alt="image">
                        </div>
                        <!-- 
                            <ul class="social-link">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                        <div class="staff-content">
                            <h3><a href="/staff/annette" target="_blank">{{ $t('StaffName-Annette')}}</a></h3>
                            <span>{{ $t('StaffTitle-Annette') }}</span><br />
                            <span><a href="mailto:Contact@pcwlaw.co.nz">Contact@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/DougHickson.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3><a href="/staff/doug" target="_blank">{{ $t('StaffName-Doug')}}</a></h3>
                            <span>{{ $t('StaffTitle-Doug') }}</span><br />
                            <span><a href="mailto:Doug@pcwlaw.co.nz">Doug@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/OlindaWoodroffe.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{ $t('StaffName-Olinda') }}</h3>
                            <span>{{ $t('StaffTitle-Olinda') }}</span><br />
                            <span><a href="mailto:Info@pcwlaw.co.nz">Info@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/ColinWoodroffe.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{ $t('StaffName-Colin') }}</h3>
                            <span>{{ $t('StaffTitle-Colin') }}</span><br />
                            <span><a href="mailto:Info@pcwlaw.co.nz">Info@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/Jeff.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3><a href="/staff/jeff" target="_blank">{{ $t('StaffName-Jeff')}}</a></h3>
                            <span>{{ $t('StaffTitle-Jeff') }}</span><br />
                            <span><a href="mailto:Jeff@pcwlaw.co.nz">Jeff@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/Donnelle.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3><a href="/staff/donnell" target="_blank">{{ $t('StaffName-Donnelle')}}</a></h3>
                            <span>{{ $t('StaffTitle-Donnelle') }}</span><br />
                            <span><a href="mailto:Info@pcwlaw.co.nz">Info@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/LeiWANG.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{ $t('StaffName-Lei') }}</h3>
                            <span>{{ $t('StaffTitle-Lei') }}</span><br />
                            <span><a href="mailto:Lei@pcwlaw.co.nz">Lei@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/Jessie.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{ $t('StaffName-Jessie') }} </h3>
                            <span>{{ $t('StaffTitle-Jessie') }}</span><br />
                            <span><a href="mailto:litigation@pcwlaw.co.nz">Litigation@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/WilliamJing.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{ $t('StaffName-William') }}</h3>
                            <span>{{ $t('StaffTitle-William') }}</span><br />
                            <span><a href="mailto:Account@pcwlaw.co.nz">Account@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <!--                 <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/LiweiGuo.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{$t('StaffName-LiWei')}}</h3>
                            <span>{{$t('StaffTitle-LiWei')}}</span><br />
                            <span><a href="mailto:Info@pcwlaw.co.nz">Info@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div> -->

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/staff_profile_default.png" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3><a href="/staff/sean" target="_blank">{{ $t('StaffName-Sean')}}</a></h3>
                            <span>{{ $t('StaffTitle-Sean') }}</span><br />
                            <span><a href="mailto:Info@pcwlaw.co.nz">Info@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/ChiXu.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{$t('StaffName-ChiXu')}}</h3>
                            <span>{{$t('StaffTitle-ChiXu')}}</span><br />
                            <span><a href="mailto:Info@pcwlaw.co.nz">Info@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="staff-card without-border without-border-radius">
                        <div class="staff-image">
                            <img src="../../assets/img/more-home/staff/Daniel.jpg" alt="image">
                        </div>
                        <div class="staff-content">
                            <h3>{{ $t('StaffName-Daniel') }}</h3>
                            <span>{{ $t('StaffTitle-Daniel') }}</span><br />
                            <span><a href="mailto:info@pcwlaw.co.nz">Info@pcwlaw.co.nz</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, defineComponent } from 'vue'

export default {
    name: 'OurStaff'
}
</script>

<style scoped>
.wrapper {
    color: #000;
}

.content {
    padding: 20px;
}

.title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

button.btn--outline-gray {
    background-color: #532c2d;
    color: #ffffff;
}

button.popup {
  background-color: #532c2d; /* Light blue */
  border: none;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}

button.popup:hover {
  background-color: #lightblue;
}

button.popup:active {
  background-color: #lightblue;
}
</style>