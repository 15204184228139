<template>
    <div class="one-header-top two-header-top">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="header-social">
                        <ul>
                            <li>
                                <a href="https://facebook.com/" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://pinterest.com/" target="_blank">
                                    <i class='bx bxl-pinterest-alt'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="header-partner">
                        <p>We recognize this is a difficult time for many people. <a href="#" target="_blank">Become a Partner</a></p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="header-call">
                        <ul>
                            <li>
                                <span>Call Us:</span> <a href="tel:+4234543123678">+4 234-543 123 678</a>
                            </li>
                            <li>
                                <span>Mail Us:</span> <a href="mailto:info@cavo.com">info@cavo.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopHeaderTwo'
}
</script>