<template>
    <div class="five-counter-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='90' 
                                :duration='9' 
                            />
                        </h3>
                        <span>CUSTOMER WORLDWIDE</span>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item counter-two">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='20' 
                                :duration='9' 
                            />
                        </h3>
                        <span>VOLUNTEERS PARTICIPATED</span>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='56' 
                                :duration='9' 
                            />
                        </h3>
                        <span>VETERAN HOME COMPLETED</span>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item counter-two">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='65' 
                                :duration='9' 
                            />
                        </h3>
                        <span>YEARS OF EXPERIENCE</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Counter'
}
</script>