<template>
    <div id="about" class="about-style-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-style-image">
                        <img src="../../assets/img/more-home/about/about.jpg" alt="image">

                        <div class="quote-content">
                            <p>“Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.”</p>
                            <span>Brokly Simmons</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-style-content">
                        <span class="sub-title">About Our Company</span>
                        <h3>Why Should You Choose Us?</h3>
                        <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <div class="counter-box">
                                    <h4>20+</h4>
                                    <span>Years Of Experience</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="counter-box">
                                    <h4>90%</h4>
                                    <span>Customer Worldwide</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>