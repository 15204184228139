<template>
    <div>
        <div id="about" class="two-mission-area pt-100">
            <div class="container">
                <div class="one-section-title">
                    <span class="sub-title">GET INTRODUCED</span>
                    <h2>Our Mission Is To Become The Best Mortgage</h2>
                </div>
                <div class="mission-content">
                    <img src="../../assets/img/home-two/mission.jpg" alt="Mission">
                    <div class="mission-bottom">
                        <ul class="align-items-center">
                            <li>
                                <a 
                                    href="javascript:void(0)" 
                                    v-on:click="isPopupMethod(isPopup)"
                                >
                                    <i class='bx bx-play' ></i>
                                </a>
                                <p>Intro With Compnay</p>
                            </li>
                            <li>
                                <div class="mission-item">
                                    <i class='bx bxs-user'></i>
                                    <span>Speak With Loan Officer</span>
                                </div>
                            </li>
                            <li>
                                <div class="mission-item">
                                    <i class='bx bx-certification'></i>
                                    <span>Tools Resource</span>
                                </div>
                            </li>
                            <li>
                                <div class="mission-item">
                                    <i class='bx bxs-hand-right'></i>
                                    <span>Get Started With Cavo</span>
                                </div>
                            </li>
                            <li>
                                <div class="mission-item">
                                    <i class='bx bxs-home-circle'></i>
                                    <span>See Home Pricing</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetIntroduced',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>