<template>
    <div class="testimonials-style-area-with-bg ptb-100">
        <div class="container">
            <div class="testimonials-style-content">
                <span class="sub-title">Testimonials</span>
                <h3>People Are Saying About Us.</h3>

                <div class="testimonials-style-slider">
                    <carousel 
                        :autoplay="5000"
                        :wrap-around="true"
                    >
                        <slide v-for="slide in carouselItems" :key="slide.id">
                            <div class="testimonials-style-card">
                                <div class="info">
                                    <i class='bx bxs-quote-alt-left'></i>
                                    <h4>{{slide.name}}</h4>
                                    <span>{{slide.position}}</span>
                                </div>
                                <p>{{slide.desc}}</p>
                                <ul class="star-list">
                                    <li><i class='bx bx-star'></i></li>
                                    <li><i class='bx bx-star'></i></li>
                                    <li><i class='bx bx-star'></i></li>
                                    <li><i class='bx bx-star'></i></li>
                                    <li><i class='bx bx-star'></i></li>
                                </ul>
                            </div>
                        </slide>

                        <template #addons>
                            <Pagination />
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                name: 'Leslie Williamson',
                position: 'Homeowners',
                desc: '“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”'
            },
            {
                id: 2,
                name: 'Leslie Williamson',
                position: 'Homeowners',
                desc: '“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”'
            },
            {
                id: 2,
                name: 'Leslie Williamson',
                position: 'Homeowners',
                desc: '“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”'
            },
        ],
    }),
})
</script>