<template>
    <div class="offer-style-area pt-100 pb-70">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Why Choose Us</span>
                <h2>We Have Offer To You</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="offer-style-card">
                        <div class="offer-image">
                            <img src="../../assets/img/more-home/offer/offer-1.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Delivering A Great Customer Experience</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="offer-style-card">
                        <div class="offer-image">
                            <img src="../../assets/img/more-home/offer/offer-2.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="offer-style-card">
                        <div class="offer-image">
                            <img src="../../assets/img/more-home/offer/offer-3.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>