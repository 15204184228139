<template>
    <div>
        <div id="home" class="three-banner-area four-banner-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-7">
                                <div class="banner-content">
                                    <span>
                                        <img src="../../assets/img/home-two/banner-icon.png" alt="Banner">
                                        Welcome To Cavo
                                    </span>
                                    <h1>Get Home From Your Choice</h1>
                                    <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tr inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>
                                    <ul>
                                        <li>
                                            <a class="cmn-banner-btn" href="#" target="_blank">
                                                Mortgage Expert
                                                <i class='bx bx-right-arrow-alt'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a 
                                                class="banner-play popup-youtube"
                                                href="javascript:void(0)"  
                                                v-on:click="isPopupMethod(isPopup)"
                                            >
                                                <i class='bx bx-play'></i>
                                            </a>
                                            <span>How It’s Work</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="banner-form">
                                    <div class="one-section-title three-section-title">
                                        <span class="sub-title"># LET’S CONTACT</span>
                                        <h2>Mortgage calculator</h2>
                                    </div>
                                    <div class="tabs">
                                        <ul class="tabs__header">
                                            <li 
                                                class="tabs__header-item" 
                                                v-for="tab in tabs" 
                                                v-on:click="selectTab (tab.id)" 
                                                v-bind:class="{ 'active': activeTab == tab.id }"
                                                :key="tab.id"
                                            >
                                                {{tab.name}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tabs__container">
                                        <ul 
                                            class="tabs__list"
                                            ref='tabsList'
                                        >
                                            <li 
                                                class="tabs__list-tab"
                                                v-for="tab in tabs"
                                                v-bind:class="{ 'active': activeTab == tab.id }"
                                                :key="tab.id"
                                            >
                                                <form>
                                                    <div class="row">
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>
                                                                    <i class='bx bx-money'></i>
                                                                </label>
                                                                <input type="text" class="form-control" placeholder="Purchase price*">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>
                                                                    <i class='bx bx-dollar-circle'></i>
                                                                </label>
                                                                <input type="text" class="form-control" placeholder="Estimated monthly*">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>
                                                                    <i class='bx bxs-badge-dollar'></i>
                                                                </label>
                                                                <input type="text" class="form-control" placeholder="Down payment*">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>
                                                                    <i class='bx bx-code-block'></i>
                                                                </label>
                                                                <input type="text" class="form-control" placeholder="Property ZIP code*">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>
                                                                    <i class='bx bxs-check-circle'></i>
                                                                </label>
                                                                <select class="form-select" aria-label="Default select example">
                                                                    <option selected>Open this select menu</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>
                                                                    <i class='bx bx-dollar'></i>
                                                                </label>
                                                                <input type="text" class="form-control" placeholder="Load Amount*">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>
                                                                    <i class='bx bx-message-square-detail'></i>
                                                                </label>
                                                                <input type="email" class="form-control" placeholder="Email address*">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <button type="submit" class="btn cmn-banner-btn">
                                                                Calculate Payment
                                                                <i class='bx bx-right-arrow-alt'></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    name: 'Purchase',
                },
                {
                    id: 2,
                    name: 'Refinance',
                },
            ]
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        },
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        }
    }
}
</script>