<template>
    <div id="home" class="confidence-home-area-wrap">
        <div class="container">
            <div class="confidence-home-wrap-content">
                <span class="sub-title">Welcome to Cavo</span>
                <h1>Buy A <b>Home</b> By Confidence</h1>
                <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>

                <ul class="confidence-home-btn">
                    <li>
                        <a href="#" class="main-default-btn">Apply Now</a>
                    </li>
                    
                    <li>
                        <a href="#" class="main-optional-btn">Mortgage Expert</a>
                    </li>
                </ul>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>