<template>
    <div class="one-faq-area ptb-100">
        <div class="container">
            <div class="faq-content">
                <accordion>
                    <accordion-item>
                        <template v-slot:accordion-trigger>
                            <a>How Do You Qualify For A Loan?</a>
                        </template>
                        <template v-slot:accordion-content>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </template>
                    </accordion-item>

                    <accordion-item>
                        <template v-slot:accordion-trigger>
                            <a>A Good Lender Will Clearly Explain Mortgage?</a>
                        </template>
                        <template v-slot:accordion-content>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </template>
                    </accordion-item>

                    <accordion-item>
                        <template v-slot:accordion-trigger>
                            <a>Can You Get A Mortgage Without A Credit Score?</a>
                        </template>
                        <template v-slot:accordion-content>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </template>
                    </accordion-item>

                    <accordion-item>
                        <template v-slot:accordion-trigger>
                            <a>How Much Home Can You Afford?</a>
                        </template>
                        <template v-slot:accordion-content>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </template>
                    </accordion-item>
                    
                    <accordion-item>
                        <template v-slot:accordion-trigger>
                            <a>How Do You Qualify For A Loan?</a>
                        </template>
                        <template v-slot:accordion-content>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </template>
                    </accordion-item>
                </accordion>
            </div>
        </div>
        <div class="faq-img">
            <img src="../../assets/img/home-one/faq-men.png" alt="FAQ">
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>