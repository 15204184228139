<template>
    <div id="about" class="about-style-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-style-full-content">
                        <span class="sub-title">About Us</span>
                        <h3>We Are Fully Dedicated To Support You</h3>
                        <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>

                        <div class="inner-content">
                            <h4>Support Service</h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        </div>
                        <div class="inner-content">
                            <h4>Refinance Guide</h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        </div>
                        <div class="about-btn">
                            <a href="#" class="main-default-btn">Learn More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-mortgage-calculator-form">
                        <h2>Mortgage calculator</h2>

                        <form>
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-money'></i>
                                </label>
                                <input type="text" class="form-control" placeholder="Purchase price*">
                            </div>
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-dollar-circle'></i>
                                </label>
                                <input type="text" class="form-control" placeholder="Estimated monthly*">
                            </div>
                            <div class="form-group">
                                <label>
                                    <i class='bx bxs-badge-dollar'></i>
                                </label>
                                <input type="text" class="form-control" placeholder="Down payment*">
                            </div>
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-code-block'></i>
                                </label>
                                <input type="text" class="form-control" placeholder="Property ZIP code*">
                            </div>
                            <div class="form-group">
                                <div class="dropdown">
                                    <label>
                                        <i class='bx bxs-check-circle'></i>
                                    </label>
                                    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Dropdown link
                                    </a>
                                    
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-dollar'></i>
                                </label>
                                <input type="text" class="form-control" placeholder="Load Amount*">
                            </div>
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-message-square-detail'></i>
                                </label>
                                <input type="email" class="form-control" placeholder="Email address*">
                            </div>
                            <button type="submit" class="main-default-btn">Calculate Payment</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>