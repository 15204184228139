export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "Header-Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCW Law \n One-Stop Law Firm"])},
  "Menu-Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Menu-AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "Menu-OurServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
  "Menu-Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
  "Menu-Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Our-Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "StaffName-PingChen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ping"])},
  "StaffTitle-PingChen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director, Barrister & Solicitor (NZ)"])},
  "StaffPing-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ping Chen’s specialty is immigration law, specialising in investment and corporate migration services, as well as IPT appeals and Judicial reviews. Her expertise in these areas is backed by a wealth of experience, starting her career as a prosecutor in China and then working alongside top litigators and KCs in Aotearoa New Zealand over the past decade.\n\nShe has appeared in numerous Chinese language radio, television and online programmes to explain local legal issues to Kiwi Chinese. During the pandemic, she hosted an online discussion with politicians. \n\nPing's unique background and diverse legal experiences has endowed her with a profound understanding of the legal and business landscape in both China and New Zealand. She recognises the significance of cultural understanding in immigration matters, legal proceedings, and negotiations, all of which enables her to effectively navigate and bridge cross-cultural complexities. "])},
  "StaffName-DrKeeTee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr. Key"])},
  "StaffTitle-DrKeeTee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director, Barrister & Solicitor (NZ), Notary Public"])},
  "StaffDrKeeTee-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr. Key H. Tee, specializing in property, trusts (including family trusts and charitable trusts) , wills and estate, and notarial works.\n\nHe graduated from the University of London, England with a Bachelor of Laws with Honours, a Barrister and Solicitor of the High Court of New Zealand, and a Notary Public appointed in the Crown Office in Chancery, London, United Kingdom.  He also has a Doctorate Degree. He has written six books (including learning Biblical Greek and Hebrew, four of which are used as textbooks in colleges overseas). He has practised law since the 1980s and became a partner in a law firm in Wellington region. In the 1990s, he established his own law firm in Auckland and later he joined PCW Law Limited.\n\nDr. Key H. Tee has more than 30 years of experience in property, wills, estates, trusts and notarial works.  He formerly is also one of the trustees of several of the New Zealand charitable trusts. He is proficient in multiple languages, including Mandarin, Cantonese, Hokkien, Malay, Indonesian, and others. He can communicate with you in the language you are most familiar with, and to understand your needs more accurately."])},
  "StaffName-Annette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette"])},
  "StaffTitle-Annette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrister & Solicitor (NZ)"])},
  "StaffAnnette-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette is a dedicated immigration lawyer with over 15 years of experience in the field, her expertise particularly shines in matters of deportation and residence appeals to the Immigration and Protection Tribunal. Annette is driven by a passion for working with people from diverse cultures across the globe, and this fervor fuels her relentless pursuit of navigating the intricate labyrinth of immigration policies on behalf of her clients.\n\nBut Annette's proficiency doesn't stop at immigration law. She also has a wealth of experience in family law, where she provides compassionate and effective legal support to families in times of conflict or transition. Her ability to empathize with her clients, combined with her strong negotiation skills, ensures she consistently achieves the best possible outcomes for those she represents.\n\nBeyond her work in immigration and family law, Annette also has a commendable proficiency in construction law. Particularly adept at handling construction contracts, she safeguards the interests of her clients by ensuring contracts are fair, compliant with regulations, and protect against potential legal issues."])},
  "StaffName-Gary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gary"])},
  "StaffTitle-Gary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barrister"])},
  "StaffGary-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gary is a professional with a unique blend of over 20 years' experience in accounting and 15 years in commercial litigation. Gary's expertise has been finely honed over the years, specializing in representing businesses, trusts, partnerships, and individual taxpayers who find themselves in challenging situations involving commercial disputes, Inland Revenue Department (IRD) disputes, and liquidation scenarios.\n\nWhat sets Gary apart is his ability to seamlessly merge his accounting acumen with his legal expertise, providing him with an in-depth understanding of financial issues in litigation proceedings. This, combined with his strong mediation skills, enables him to deftly negotiate settlements and achieve optimal outcomes for his clients.\n\nAs an experienced negotiator, Gary not only understands the art of compromise but also knows when to hold firm to ensure his clients' interests are adequately protected and advocated for. Whether you're embroiled in a commercial dispute, navigating through an IRD contention, or dealing with a liquidation process, Gary's distinctive blend of skills and experience makes him a powerful ally in resolving your legal challenges."])},
  "StaffName-Donnelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnelle"])},
  "StaffTitle-Donnelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "StaffDonnelle-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnell is a seasoned legal professional boasting 18 years of diverse experience in the field. Her journey began in a general practice, where she honed her skills in property law, a field in which she soon developed a keen specialization.\n\nThroughout her career, Donnell has been involved in a wide array of work encompassing all facets of property law, from real estate transactions and land use disputes to property development and commercial leasing. Her portfolio is distinguished by a variety of large and exciting projects that have not only provided her with a rich breadth and depth of experience, but also honed her ability to handle complex cases with a strategic, detail-oriented approach.\n\nHer robust understanding of property law, coupled with her extensive experience and passion for her work, enable Donnell to navigate even the most complicated property law matters with finesse. Whether you're dealing with a large-scale property development project, a complex real estate transaction, or a challenging land use dispute, Donnell's expertise makes her an exceptional choice to guide you through your legal journey."])},
  "StaffName-Jeff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeff"])},
  "StaffTitle-Jeff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Consultant"])},
  "StaffJeff-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a litigation lawyer with a remarkable career spanning over three decades, Jeff is well-versed in representing clients across all levels of the court, successfully managing an array of civil and commercial litigation and dispute resolution matters.\n\nOne area where Jeff truly distinguishes himself is in debt collection. His expertise in this niche was honed during his tenure as an in-house counsel with a debt collection agency. From this experience, Jeff not only amassed extensive knowledge in debt collection laws and procedures, but he also developed a nuanced understanding of the challenges and complexities inherent in this field.\n\nToday, he is a trusted legal advisor to some of the largest debt collection agencies in New Zealand. He adeptly navigates the often intricate legal landscape of debt recovery, leveraging his experience and acumen to deliver results that align with his clients' goals.\n\nWhether you're facing a civil or commercial dispute, require adept handling of debt collection matters, or need an experienced litigator to represent you in court, Jeff's proven track record and dedication to his clients make him a formidable choice for your legal needs."])},
  "StaffName-Doug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doug"])},
  "StaffTitle-Doug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "StaffDoug-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doug is an expert in the realms of commercial litigation, dispute resolution, and property litigation. Over the course of his career, Doug has built an impressive track record for resolving complex disputes, adeptly navigating the intricate landscape of commercial and property litigation.\n\nWhether it's a dispute involving a business contract, a real estate transaction, or a contentious property-related matter, Doug brings a strategic and detail-oriented approach to each case he handles. His deep understanding of both the commercial and property sectors, combined with his relentless drive to deliver the best possible outcomes, enable him to guide clients through complex litigation and dispute resolution processes with ease and confidence\n\nThrough every step of the litigation process, Doug's clients can rely on his knowledge, skills, and unwavering dedication to achieving their objectives. His ability to analyze each dispute from a comprehensive legal and business perspective makes him a formidable ally in any contentious situation."])},
  "StaffName-Olinda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olinda"])},
  "StaffTitle-Olinda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Consultant"])},
  "StaffName-Sean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sean"])},
  "StaffTitle-Sean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "StaffSean-Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sean is a seasoned legal veteran with an impressive career spanning 26 years, specializing in corporate and commercial law. Sean's unparalleled expertise has been instrumental in successfully navigating a broad range of legal matters for businesses of all sizes, from fledgling start-ups to established Fortune 500 companies.\n\n What truly sets Sean apart, however, is his multifaceted skillset that extends beyond the legal sphere. Alongside his law degrees, he holds a PRINCE 2 certification in IT project management. This unique combination of credentials equips Sean with a comprehensive understanding of both the legal and technological landscapes. He effortlessly straddles the worlds of law and IT, leveraging his extensive project management experience to deliver results that align with his clients' strategic objectives.\n\nSean's legal acumen, robust IT project management skills, and commitment to his clients make him an invaluable asset in today's complex business landscape. Whether you're seeking legal counsel for your business or need a seasoned project manager for your next IT initiative, Sean's breadth and depth of experience ensure he is well-equipped to guide you towards success."])},
  "StaffName-Colin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colin"])},
  "StaffTitle-Colin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior Consultant"])},
  "StaffName-Lei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lei"])},
  "StaffTitle-Lei": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eastern Office Manager"]), _normalize(["Registered Legal Executive"])])},
  "StaffName-Eric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eric"])},
  "StaffTitle-Eric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Development Manager"])},
  "StaffName-Yue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yue"])},
  "StaffTitle-Yue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Executive"])},
  "StaffName-Katharine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katharine"])},
  "StaffTitle-Katharine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law Clerk"])},
  "StaffName-William": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["William"])},
  "StaffTitle-William": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust Accountant"])},
  "StaffName-LiWei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi"])},
  "StaffTitle-LiWei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Assistant"])},
  "StaffName-Echo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echo"])},
  "StaffTitle-Echo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Executive (maternity leave)"])},
  "StaffName-Jessie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jessie"])},
  "StaffTitle-Jessie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrolled Barrister & Solicitor"])},
  "StaffName-Daniel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daniel"])},
  "StaffTitle-Daniel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law Clerk"])},
  "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About PCW Law"])},
  "about-us-why-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why choose us?"])},
  "about-us-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCW Law is a premier law firm based in Auckland, with two conveniently located offices in New Market and Meadowlands.<br /> Our team comprises highly skilled lawyers with 20+ years of experience in a wide range of legal fields, including property, family, immigration, litigation, commercial, dispute resolution, and Notary public.<br />At PCW Law, we offer comprehensive legal services to both domestic and international clients, including multinational corporations, small and medium-sized enterprises, and individuals. <br />We have built a robust reputation for our excellence and professionalism in the legal industry. It gives us immense pride to offer tailored and efficient solutions that cater to our clients' diverse needs in a language that they are most comfortable with！"])},
  "our-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our service"])},
  "our-service-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "our-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Goal"])},
  "our-goal-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing you with timely, professional, and efficient legal services in the language you are familiar with!"])},
  "our-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])},
  "our-vision-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The principles of handling cases are independent thinking, focusing on key points, careful discernment, and maintaining confidentiality. We do not fear strong opponents or difficulties, and insist on defending the legal rights of the parties as the foundation of our work. "])},
  "property-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conveyancing\n\n"])},
  "property-settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property settlement"])},
  "property-due-diligence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property due diligence"])},
  "property-refinance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property refinance"])},
  "property-subdivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property subdivision"])},
  "property-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property consent"])},
  "property-keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property keys"])},
  "commercial-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Law"])},
  "commercial-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Purchase"])},
  "commercial-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Property"])},
  "commercial-rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Rental"])},
  "commercial-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Share"])},
  "commercial-merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Merge"])},
  "commercial-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
  "family-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Law\n\n"])},
  "family-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship Property"])},
  "family-divorce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissolution of Marriage"])},
  "family-guardianship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parenting Order"])},
  "family-prenuptial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separation/Contract Out Agreement"])},
  "family-restraining-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Removal Order"])},
  "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust & Will\n\n"])},
  "trust-family-trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Trust"])},
  "trust-will": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will"])},
  "immigration-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immigration Law"])},
  "immigration-visa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa Application"])},
  "immigration-investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor Visa Application"])},
  "immigration-family-union": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Category Visa"])},
  "immigration-overstay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section61 Application & Minister Special Direction"])},
  "immigration-deportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Deportation Liability"])},
  "immigration-complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immigration & Protection Tribunal"])},
  "immigration-judicial-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immigration Judicial Review"])},
  "employment-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Law"])},
  "employment-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Agreement"])},
  "employment-dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Employment Dispute"])},
  "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litigation\n\n"])},
  "dispute-civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil Litigation"])},
  "dispute-commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Criminal Litigation- King's Counsel- Paul Dacre K.C."])},
  "liability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt Collection"])},
  "liability-personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Debt"])},
  "liability-commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Debt"])},
  "liability-bankruptcy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Liquidation"])},
  "notary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notary\n\n"])},
  "notary-international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notary Public"])},
  "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intellectual property"])},
  "ip-trademarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade Mark Registration"])},
  "ip-copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright Protection"])},
  "criminal-law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Criminal Law (King's Counsel) "])},
  "criminal-drink-driving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drunk Driving"])},
  "criminal-drug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drug"])},
  "criminal-harm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Assault"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "contact-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "StaffName-Amber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amber"])},
  "StaffTitle-Amber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law Clerk"])},
  "StaffName-ChiXu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Xu"])},
  "StaffTitle-ChiXu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law Clerk"])},
  "OnlinePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Payment"])}
}