<template>
    <div class="one-help-area two-help-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-7 p-0">
                    <div class="help-item">
                        <div class="one-section-title">
                            <span class="sub-title">FUN FACT</span>
                            <h2>There Is Some Fact That You Know</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <ul>
                            <li>
                                <div class="help-icon">
                                    <i class='bx bx-brain'></i>
                                </div>
                                <div class="help-inner">
                                    <h3>Loan Officer Assistant do?</h3>
                                    <p>When you own your own homet seeturlit sed dolor sit am</p>
                                </div>
                            </li>
                            <li>
                                <div class="help-icon">
                                    <i class='bx bx-home-alt'></i>
                                </div>
                                <div class="help-inner">
                                    <h3>RMS is Working Harder </h3>
                                    <p>You can grow in your new homeit amet seeturlit sed dolor sit </p>
                                </div>
                            </li>
                        </ul>
                        <div class="advisor-link">
                            <a href="#" target="_blank">
                                KNOWLEDGE CENTER
                            </a>
                            <i class="bx bx-right-arrow-alt"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 p-0">
                    <div class="help-img-two">
                        <img src="../../assets/img/home-two/fun.jpg" alt="Fun">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FunFact'
}
</script>