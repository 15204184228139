<template>
    <nav :class="['navbar navbar-expand-md navbar-light navbar-light-four', {'is-sticky': isSticky}]">
        <router-link class="navbar-brand" to="/">
            <img src="../../assets/img/logo-two.png" alt="Logo">
        </router-link>
        <div 
            class="navbar-toggler" 
            @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ 'active': button_active_state }"
            v-on:click="button_active_state = !button_active_state"
        >
            <i class='bx bx-menu'></i>
            <i class='bx bx-x'></i>
        </div>
        
        <div class="collapse navbar-collapse" :class="{ toggler: active }">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('home')" href="javascript:void(0)">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('about')" href="javascript:void(0)">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('service')" href="javascript:void(0)">Our Service</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('team')" href="javascript:void(0)">Team</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('blog')" href="javascript:void(0)">Blog</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('contact')" href="javascript:void(0)">Contact</a>
                </li>
            </ul>
        </div>
        <div class="side-nav">
            <a class="side-nav-left" href="#">Sign In</a>
            <a class="side-nav-right" href="#">
                <i class='bx bx-link-external'></i>
                Sign Up
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavbarTwo',
    data(){
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
    methods: {
        scroll(refName) {
            const element = document.getElementById(refName);
            element.scrollIntoView({behavior: "smooth"})
        }
    }
}
</script>