<template>
    <div>
        <NavbarThree class="navbar-style-black-color" />
        <MainBanner />
        <Information class="with-bg-black-color" />
        <About />
        <Expect />
        <Funfact />
        <MortgageQuote />
        <OurStaff class="pt-100" />
        <GoBeyond />
        <FindOwnLoan />
        <Overview />
        <Testimonials />
        <Faq />
        <Blog />
        <Contact />
        <SubscribeTwo class="bg-style-two" />
        <FooterThree />
    </div>
</template>

<script>
import NavbarThree from '../Layouts/NavbarThree'
import MainBanner from '../HomeTen/MainBanner'
import Information from '../Common/Information'
import About from '../HomeTen/About'
import Expect from '../HomeTen/Expect'
import Funfact from '../HomeTen/Funfact'
import MortgageQuote from '../HomeTen/MortgageQuote'
import OurStaff from '../Common/OurStaff'
import GoBeyond from '../HomeTen/GoBeyond'
import FindOwnLoan from '../HomeTen/FindOwnLoan'
import Overview from '../HomeTen/Overview'
import Testimonials from '../HomeTen/Testimonials'
import Faq from '../HomeTen/Faq'
import Blog from '../HomeTen/Blog'
import Contact from '../HomeTen/Contact'
import SubscribeTwo from '../Common/SubscribeTwo'
import FooterThree from '../Layouts/FooterThree'

export default {
    name: 'HomePageTen',
    components: {
        NavbarThree,
        MainBanner,
        Information,
        About,
        Expect,
        Funfact,
        MortgageQuote,
        OurStaff,
        GoBeyond,
        FindOwnLoan,
        Overview,
        Testimonials,
        Faq,
        Blog,
        Contact,
        SubscribeTwo,
        FooterThree,
    }
}
</script>