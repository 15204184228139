<template>
    <div>
        <!-- <TopHeaderOne /> -->
        <NavbarOne />
        <MainBanner />
        <AboutOurCompany />
        <!-- <Counter /> -->
        <OurStaff />
        <OurCoreService />
        <!-- <BuyingHome /> -->
        <!-- <WhyChooseUs /> -->
        <!-- <Quote /> -->
        <!-- <AmongThem subTitle="WHY CHOOSE US" heading="Among Them We’re" /> -->
        <!-- <Faq /> -->
        <Review />
        <Contact />
        <!-- <Blog /> -->
        <Map />
        <Footer />
    </div>
</template>

<script>
import TopHeaderOne from '../Layouts/TopHeaderOne'
import NavbarOne from '../Layouts/NavbarOne'
import MainBanner from '../HomeOne/MainBanner'
import AboutOurCompany from '../HomeOne/AboutOurCompany'
import Counter from '../Common/Counter'
import OurCoreService from '../Common/OurCoreService'
import WhyChooseUs from '../HomeOne/WhyChooseUs'
import AmongThem from '../Common/AmongThem'
//import StaffProfiles from '../Common/StaffProfiles'
import OurStaff  from '../Common/OurStaff'
import Faq from '../HomeOne/Faq'
import Review from '../HomeOne/Review'
import Contact from '../HomeOne/Contact'
import Blog from '../Common/Blog'
import Footer from '../Layouts/FooterPCW'

export default {
    name: 'HomePageOne',
    components: {
        TopHeaderOne,
        NavbarOne,
        MainBanner,
        AboutOurCompany,
        Counter,
        OurCoreService,
        WhyChooseUs,
        Review,
        AmongThem,
        OurStaff,
        Faq,
        Contact,
        Blog,
        Footer,
    }
}
</script>