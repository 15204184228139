<template>
    <div>
        <div class="video-overview-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="video-overview-image">
                            <img src="../../assets/img/more-home/video-overview.jpg" alt="image">

                            <a 
                                href="javascript:void(0)" 
                                class="video-btn"
                                v-on:click="isPopupMethod(isPopup)"
                            >
                                <i class='bx bx-play' ></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="video-overview-content">
                            <div class="overview-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h3>Mortgage Pre-Approval</h3>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.</p>
                                <a href="#">Read More</a>
                            </div>

                            <div class="overview-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h3>House Shopping</h3>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.</p>
                                <a href="#">Read More</a>
                            </div>

                            <div class="overview-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h3>Loan Application</h3>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.</p>
                                <a href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoOverview',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>