<template>
    <div class="three-loan-area five-loan-area six-loan-area ptb-100">
        <div class="container">
            <div class="five-loan-slider">
                <carousel 
                    :autoplay="5000"
                    :wrap-around="true"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="loan-item">
                            <i :class='slide.icon'></i>
                            <h3>{{slide.title}}</h3>
                            <p>{{slide.description}}</p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class='bx bx-right-arrow-alt'></i>
                            </a>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'Loan',
    components: {
        Carousel,
        Slide,
        Pagination
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                icon: 'bx bx-home',
                title: 'A Conventional Home Loan',
                description: 'Fyigration do amet contur diisci velit suia nonnua'
            },
            {
                id: 2,
                icon: 'bx bx-wrench',
                title: 'Homeready Fannie Mortgage',
                description: 'Fyigration do amet contur diisci velit suia nonnua'
            },
            {
                id: 3,
                icon: 'bx bx-dollar-circle',
                title: 'High Balance Mortgage',
                description: 'Wigration do amet contur diisci velit suia nonnua'
            },
            {
                id: 4,
                icon: 'bx bx-donate-heart',
                title: 'Renovation Loans For Buyers',
                description: 'Migration do amet contur diisci velit suia nonnua'
            },
            {
                id: 5,
                icon: 'bx bx-home',
                title: 'A  Conventional Home Loan',
                description: 'Migration do amet contur diisci velit suia nonnua'
            },
            {
                id: 6,
                icon: 'bx bx-wrench',
                title: 'Homeready Fannie Mortgage',
                description: 'Fyigration do amet contur diisci velit suia nonnua'
            },
            {
                id: 7,
                icon: 'bx bx-dollar-circle',
                title: 'High Balance Mortgage',
                description: 'Wigration do amet contur diisci velit suia nonnua'
            },
            {
                id: 8,
                icon: 'bx bx-donate-heart',
                title: 'Renovation Loans For Buyers',
                description: 'Migration do amet contur diisci velit suia nonnua'
            },
        ],
        // carousel settings
        settings: {
            snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            600: {
                itemsToShow: 2,
                snapAlign: 'end',
            },
            1000: {
                itemsToShow: 4,
                snapAlign: 'end',
            },
        },
    }),
})
</script>