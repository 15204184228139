<template>
    <div>
        <div id="home" class="choice-home-area">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="choice-home-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Get Home From Your Choice</h1>
        
                            <ul class="choice-home-btn">
                                <li>
                                    <a href="#" class="main-default-btn">Mortgage Expert</a>
                                </li>
                                <li>
                                    <a 
                                        href="javascript:void(0)" 
                                        class="video-btn"
                                        v-on:click="isPopupMethod(isPopup)"
                                    >
                                        <i class="bx bx-play"></i> 
                                        <span>Intro With Company</span>
                                    </a>
                                </li>
                            </ul>
                        </div> 
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="mortgage-calculator-form">
                            <h2>Mortgage calculator</h2>

                            <form>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-money'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Purchase price*">
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-dollar-circle'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Estimated monthly*">
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bxs-badge-dollar'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Down payment*">
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-code-block'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Property ZIP code*">
                                </div>
                                <div class="form-group">
                                    <div class="dropdown">
                                        <label>
                                            <i class='bx bxs-check-circle'></i>
                                        </label>
                                        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Dropdown link
                                        </a>
                                        
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" href="#">Action</a>
                                            <a class="dropdown-item" href="#">Another action</a>
                                            <a class="dropdown-item" href="#">Something else here</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-dollar'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Load Amount*">
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-message-square-detail'></i>
                                    </label>
                                    <input type="email" class="form-control" placeholder="Email address*">
                                </div>
                                <button type="submit" class="main-default-btn">Calculate Payment</button>
                            </form>
                        </div>

                        <ul class="choice-home-social-links">
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <ul class="choice-home-information">
                <li>
                    <span>Call Us:</span> <a href="tel:4234543123678">+4 234-543 123 678</a>
                </li>
                <li>
                    <span>Mail Us:</span> <a href="mailto:info@cavo.com">info@cavo.com</a>
                </li>
            </ul>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>