<template>
    <div class="need-help-faq-area pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="need-help-faq-content">
                        <span class="sub-title">FAQ</span>
                        <h3>Need Help? Read Popular Questions</h3>

                        <div class="need-help-faq-accordion">
                            <div class="accordion" id="FaqAccordion">
                                <div class="accordion-item">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How Do You Qualify For A Loan?
                                    </button>
                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        A Good Lender Will Clearly Explain Mortgage?
                                    </button>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Can You Get A Mortgage Without A Credit Score?
                                    </button>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        How Much Home Can You Afford?
                                    </button>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="need-help-faq-image">
                        <img src="../../assets/img/more-home/need-help-faq.jpg" alt="image">

                        <div class="help-box">
                            <h3>Find</h3>
                            <span>Questions & Answers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Faq'
}
</script>