<template>
    <div class="two-lover-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">COUSTOMER FEEDBACKS</span>
                <h2>What People Say</h2>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="lover-item">
                        <ul class="align-items-center">
                            <li>
                                <div class="lover-img">
                                    <img src="../../assets/img/home-two/lover1.png" alt="Lover">
                                    <i class='bx bxs-quote-alt-left'></i>
                                </div>
                            </li>
                            <li>
                                <div class="lover-content">
                                    <h3>Donney Jon</h3>
                                    <span>Perisian Org</span>
                                    <p>I had ipsum dolor sit a consetusicing elit sed do eiusmod mpor nnim ad mini eiusmod nnim </p>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="lover-item">
                        <ul class="align-items-center">
                            <li>
                                <div class="lover-img">
                                    <img src="../../assets/img/home-two/lover2.png" alt="Lover">
                                    <i class='bx bxs-quote-alt-left'></i>
                                </div>
                            </li>
                            <li>
                                <div class="lover-content">
                                    <h3>Oli Rubion</h3>
                                    <span>Entoin Ect</span>
                                    <p>Realy ipsum dolor sit a consetusicing elit sed do eiusmod mpor nnim ad mini eiusmod nnim </p>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CoustomerFeedbacks'
}
</script>