<template>
    <div class="one-faq-area four-faq-area six-faq-area pt-100 pb-70">
        <div class="faq-shape">
            <img src="../../assets/img/home-six/faq4.png" alt="FAQ">
        </div>
        <div class="container-fluid p-0">
            <div class="row align-items-center m-0">
                <div class="col-lg-6 pl-0">
                    <div class="faq-img">
                        <img src="../../assets/img/home-six/faq1.png" alt="Faq">
                        <img src="../../assets/img/home-six/faq2.png" alt="Faq">
                        <img src="../../assets/img/home-six/faq3.png" alt="Faq">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-content">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>How Do You Qualify For A Loan?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>A Good Lender Will Clearly Explain Mortgage?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>Can You Get A Mortgage Without A Credit Score?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>How Much Home Can You Afford?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>
                            
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>How Do You Qualify For A Loan?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>A Good Lender Will Clearly Explain Mortgage?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>