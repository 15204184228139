<template>
    <div>
        <NavbarThree class="navbar-style-two" />
        <MainBanner />
        <Information />
        <About />
        <MortgageQuote />
        <WhyChooseUs />
        <GoBeyond />
        <Funfact />
        <FindOwnLoan />
        <Faq />
        <OurStaff />
        <Contact />
        <Testimonials />
        <Overview />
        <Blog />
        <SubscribeTwo />
        <FooterThree />
    </div>
</template>

<script>
import NavbarThree from '../Layouts/NavbarThree'
import MainBanner from '../HomeNine/MainBanner'
import Information from '../Common/Information'
import About from '../HomeNine/About'
import MortgageQuote from '../HomeNine/MortgageQuote'
import WhyChooseUs from '../HomeNine/WhyChooseUs'
import GoBeyond from '../HomeNine/GoBeyond'
import Funfact from '../HomeNine/Funfact'
import FindOwnLoan from '../HomeNine/FindOwnLoan'
import Faq from '../HomeNine/Faq'
import OurStaff  from '../Common/OurStaff'
import Contact from '../HomeNine/Contact'
import Testimonials from '../HomeNine/Testimonials'
import Overview from '../HomeNine/Overview'
import Blog from '../HomeNine/Blog'
import SubscribeTwo from '../Common/SubscribeTwo'
import FooterThree from '../Layouts/FooterThree'

export default {
    name: 'HomePageNine',
    components: {
        NavbarThree,
        MainBanner,
        Information,
        About,
        MortgageQuote,
        WhyChooseUs,
        GoBeyond,
        Funfact,
        FindOwnLoan,
        Faq,
        OurStaff,
        Contact,
        Testimonials,
        Overview,
        Blog,
        SubscribeTwo,
        FooterThree,
    }
}
</script>