<template>
    <div>
        <TopHeaderTwo />
        <NavbarOne />
        <MainBanner />
        <Quote />
        <Loan />
        <AboutUs />
        <ContactWithUs />
        <Counter />
        <StepsProcess class="five-step-area" />
        <ChooseYourService />
        <TeamLeadership />
        <CategoriesOfLoan class="seven-loan-area" />
        <Time />
        <Faq />
        <Blog class="five-blog-area" />
        <Testimonial />
        <Subscription />
        <Map />
        <Hour />
        <Footer />
    </div>
</template>

<script>
import TopHeaderTwo from '../Layouts/TopHeaderTwo'
import NavbarOne from '../Layouts/NavbarOne'
import MainBanner from '../HomeSix/MainBanner'
import Quote from '../HomeSix/Quote'
import Loan from '../HomeSix/Loan'
import AboutUs from '../HomeSix/AboutUs'
import ContactWithUs from '../HomeSix/ContactWithUs'
import Counter from '../HomeSix/Counter'
import StepsProcess from '../HomeSix/StepsProcess'
import ChooseYourService from '../HomeSix/ChooseYourService'
import TeamLeadership from '../HomeSix/TeamLeadership'
import CategoriesOfLoan from '../Common/CategoriesOfLoan'
import Time from '../HomeSix/Time'
import Faq from '../HomeSix/Faq'
import Blog from '../Common/Blog'
import Testimonial from '../HomeSix/Testimonial'
import Subscription from '../HomeSix/Subscription'
import Map from '../Common/Map'
import Hour from '../HomeSix/Hour'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageSix',
    components: {
        TopHeaderTwo,
        NavbarOne,
        MainBanner,
        Quote,
        Loan,
        AboutUs,
        ContactWithUs,
        Counter,
        StepsProcess,
        ChooseYourService,
        TeamLeadership,
        CategoriesOfLoan,
        Time,
        Faq,
        Blog,
        Testimonial,
        Subscription,
        Map,
        Hour,
        Footer,
    }
}
</script>