<template>
    <footer class="one-footer-area pt-100">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-logo">
                            <a href="#">
                                <img src="../../assets/img/logo-two.png" alt="Logo">
                            </a>
                            <p>Cavo Financial Services, Inc. 200 Wood Avenue South, Ninth Floor Iselin, NJ 65432</p>
                            <p>Resouece Financial ServicInc. 200 Wood Avenue South</p>
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.twitter.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-quick">
                            <h3>Quick Links</h3>
                            <ul>
                                <li>
                                    <a href="#" target="_blank">Mortgage Calculator</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Mortgage Checklist</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Mortgage Glossary</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Renting vs Owning</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Credit Basics</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Disaster Relief Center</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Disclosure</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Home Disclosure Notice</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-quick">
                            <h3>About Us</h3>
                            <ul>
                                <li>
                                    <a href="#" target="_blank">Down Payment Options</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Your Mortgage Payment</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Appraisal Basics</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Contact</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Careers</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Privacy Choices</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Licencing</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-address">
                            <h3>Address</h3>
                            <ul>
                                <li>
                                    <i class='bx bx-phone-call'></i>
                                    <a href="tel:+4498886660000">+449 888 666 0000</a>
                                </li>
                                <li>
                                    <i class='bx bx-phone-call'></i>
                                    <a href="tel:+5498586560350">+549 858 656 0350</a>
                                </li>
                                <li>
                                    <i class='bx bx-message-square-detail'></i>
                                    <a href="mailto:hello@cavo.com">hello@cavo.com</a>
                                </li>
                                <li>
                                    <i class='bx bx-message-square-detail'></i>
                                    <a href="mailto:info@cavo.com">info@cavo.com</a>
                                </li>
                                <li>
                                    <i class='bx bx-current-location' ></i>
                                    855 road, broklyn street new york 600
                                </li>
                                <li>
                                    <i class='bx bx-planet' ></i>
                                    Cavo is not acting behalf the Federal Government
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-area">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="copyright-item">
                            <ul>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/visa.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/master-card.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/paypal.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/skrill.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/master-card2.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/visa2.jpg" alt="Method">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="copyright-item">
                            <p>&copy; {{currentYear}} Cavo. Designed By <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>