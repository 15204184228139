<template>
    <div class="offer-style-area-with-full-width pt-100 pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-12">
                    <div class="offer-style-section-content">
                        <span class="sub-title">Choose Your Service</span>
                        <h3>Featured Services Fou You</h3>
                        <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus pellentesque in ipsum id orci porta dapibus cras ultricies ligula sed magna dictum porta quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                    </div>
                </div>

                <div class="col-lg-9 col-md-12">
                    <div class="offer-style-slider">
                        <carousel 
                            :autoplay="5000"
                            :settings="settings"
                            :breakpoints="breakpoints"
                        >
                            <slide v-for="slide in carouselItems" :key="slide.id">
                                <div class="offer-style-item">
                                    <div class="offer-image">
                                        <img :src="slide.image" alt="image">
                                    </div>
                                    <div class="offer-content">
                                        <h3>{{slide.title}}</h3>
                                        <a href="#">Read More</a>
                                    </div>
                                </div>
                            </slide>

                            <template #addons>
                                <Pagination />
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'ChooseYourService',
    components: {
        Carousel,
        Slide,
        Pagination,
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/img/more-home/offer/offer-4.jpg'),
                title: 'Delivering A Great Customer Experience'
            },
            {
                id: 2,
                image: require('../../assets/img/more-home/offer/offer-5.jpg'),
                title: 'Delivering A Great Customer Experience'
            },
            {
                id: 3,
                image: require('../../assets/img/more-home/offer/offer-6.jpg'),
                title: 'Consideration Have Been Divided Get Refinance Advisor'
            },
            {
                id: 4,
                image: require('../../assets/img/more-home/offer/offer-4.jpg'),
                title: 'Delivering A Great Customer Experience'
            },
            {
                id: 5,
                image: require('../../assets/img/more-home/offer/offer-5.jpg'),
                title: 'Delivering A Great Customer Experience'
            },
            {
                id: 6,
                image: require('../../assets/img/more-home/offer/offer-6.jpg'),
                title: 'Consideration Have Been Divided Get Refinance Advisor'
            },
        ],
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
			1200: {
                itemsToShow: 3,
                snapAlign: 'center',
			}
        },
    }),
})
</script>