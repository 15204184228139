<template>
    <div class="two-banner-wrap three-banner-wrap four-banner-wrap">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-6 p-0">
                    <div class="banner-time-left">
                        <ul>
                            <li>
                                <i class='bx bx-money'></i>
                                <span>What is price?</span>
                                <p>Mortgage Rates</p>
                            </li>
                            <li>
                                <i class='bx bx-calculator'></i>
                                <span>Get calculated?</span>
                                <a href="#" target="_blank">Calculators</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 p-0">
                    <div class="banner-time-right">
                        <ul>
                            <li>
                                <i class='bx bx-dollar-circle'></i>
                                <span>What’s paying?</span>
                                <p>Payment By Credit Card</p>
                            </li>
                            <li>
                                <i class='bx bxs-contact'></i>
                                <span>Faq Or ask?</span>
                                <span>Get Quick Information</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Time'
}
</script>