<template>
    <div class="six-hour-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="hour-item">
                        <i class='bx bxs-time'></i>
                        <div class="hour-inner">
                            <h3>Opening Time</h3>
                            <span>Mon-Fri (8AM - 6PM)</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="hour-item">
                        <i class='bx bxs-quote-alt-left'></i>
                        <div class="hour-inner">
                            <h3>Get A Quote</h3>
                            <a href="#" target="_blank">Find Free Quote?</a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="hour-item">
                        <i class='bx bxs-phone-call'></i>
                        <div class="hour-inner">
                            <h3>Call Us:</h3>
                            <a href="tel:(435)-765-987">(435)-765-987</a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="hour-item hour-right">
                        <i class='bx bx-message-detail'></i>
                        <div class="hour-inner">
                            <h3>Contact Us</h3>
                            <a href="mailto:info@cavo.com">info@cavo.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Hour'
}
</script>