<template>
    <div>
        <div 
            class="demo-side-icon" 
            v-on:click="isDemoMethod(isDemo)"
        >
            <button type="button" class="modal-btn">
                <span>DEMOS</span>
            </button>
        </div>

        <div 
            class="demos-side-content" 
            v-if="isDemo"
        >
            <div class="modal-header">
                <h2>View Demo</h2>
                <button 
                    type="button" 
                    class="close" 
                    v-on:click="isDemoMethod(isDemo)"
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <ul>
                    <li>
                        <div class="demo-item">
                            <a href="/">
                                <span>Home One</span>
                                <img src="../../assets/img/demo/home-one.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-two">
                            <span>Home Two</span>
                                <img src="../../assets/img/demo/home-two.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-three">
                                <span>Home Three</span>
                                <img src="../../assets/img/demo/home-three.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-four">
                                <span>Home Four</span>
                                <img src="../../assets/img/demo/home-four.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-five">
                                <span>Home Five</span>
                                <img src="../../assets/img/demo/home-five.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-six">
                                <span>Home Six</span>
                                <img src="../../assets/img/demo/home-six.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-seven">
                                <span>Home Seven</span>
                                <img src="../../assets/img/demo/home-seven.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-eight">
                            <span>Home Eight</span>
                                <img src="../../assets/img/demo/home-eight.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-nine">
                                <span>Home Nine</span>
                                <img src="../../assets/img/demo/home-nine.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-ten">
                                <span>Home Ten</span>
                                <img src="../../assets/img/demo/home-ten.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-eleven">
                                <span>Home Eleven</span>
                                <img src="../../assets/img/demo/home-eleven.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="demo-item">
                            <a href="/home-twelve">
                                <span>Home Twelve</span>
                                <img src="../../assets/img/demo/home-twelve.jpg" alt="Demos">
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DemoSidebar',
    data (){
        return {
            isDemo: false
        }
    },
    methods: {
        isDemoMethod(isDemo){
            console.log(this.isDemo)
            return this.isDemo = !isDemo
        }
    }
}
</script>