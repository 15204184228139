<template>
  <div>
    <NavbarTwo />
    <MainBanner />
    <WhatsYouExpect />
    <AboutUs />
    <Quote />
    <ChooseYourService />
    <StepsProcess class="five-step-area" />
    <TeamLeadership class="five-team-area" />
    <Counter />
    <Faq />
    <CategoriesOfLoan />
    <Testimonial />
    <Blog class="five-blog-area" />
    <Subscribe />
    <Map />
    <FooterTwo class="five-footer-area" />
  </div>
</template>

<script>
import NavbarTwo from "../Layouts/NavbarTwo";
import MainBanner from "../HomeFive/MainBanner";
import WhatsYouExpect from "../HomeFive/WhatsYouExpect";
import AboutUs from "../HomeFive/AboutUs";
import Quote from "../HomeFive/Quote";
import ChooseYourService from "../HomeFive/ChooseYourService";
import StepsProcess from "../Common/StepsProcess";
import TeamLeadership from "../Common/TeamLeadership";
import Counter from "../HomeFive/Counter";
import Faq from "../HomeFive/Faq";
import CategoriesOfLoan from "../Common/CategoriesOfLoan";
import Testimonial from "../HomeFive/Testimonial";
import Blog from "../Common/Blog";
import Subscribe from "../HomeFive/Subscribe";
import Map from "../Common/Map";
import FooterTwo from "../Layouts/FooterTwo";

export default {
  name: "HomePageFive",
  components: {
    NavbarTwo,
    MainBanner,
    WhatsYouExpect,
    AboutUs,
    Quote,
    ChooseYourService,
    StepsProcess,
    TeamLeadership,
    Counter,
    Faq,
    CategoriesOfLoan,
    Testimonial,
    Blog,
    Subscribe,
    Map,
    FooterTwo,
  },
};
</script>
