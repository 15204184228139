<template>
    <div class="one-among-area ptb-100">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">WHY CHOOSE US</span>
                <h2>Among Them We’re</h2>
                <p>Choose started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua  </p>
            </div>
            <div class="among-slider">
                <carousel 
                    :autoplay="5000"
                    :wrap-around="true"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="among-item">
                            <div class="among-top">
                                <img :src="slide.image" alt="Among">
                                <div class="among-icon">
                                    <i :class='slide.icon'></i>
                                </div>
                            </div>
                            <div class="among-bottom">
                                <h3>{{slide.title}}</h3>
                                <p>{{slide.description}}</p>
                                <a href="#" target="_blank">
                                    READ MORE
                                    <i class="bx bx-right-arrow-alt"></i>
                                </a>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'WhyChooseUs',
    components: {
        Carousel,
        Slide,
        Pagination,
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/img/home-one/among-one.jpg'),
                icon: 'bx bxs-badge-dollar',
                title: 'Refinancing Your Home No Doubt About Anything',
                description: 'Gain home equity by paying sit amet seeturlit sed dolor se equity'
            },
            {
                id: 2,
                image: require('../../assets/img/home-one/among-two.jpg'),
                icon: 'bx bx-bulb',
                title: 'Delivering A Great Customer Experience',
                description: 'Your home equity by paying sit amet seeturlit sed dolor se equity'
            },
            {
                id: 3,
                image: require('../../assets/img/home-one/among-three.jpg'),
                icon: 'bx bx-money',
                title: 'Trusted Home Financing Experts',
                description: 'Srt home equity by paying sit amet seeturlit sed dolor se equity'
            },
            {
                id: 4,
                image: require('../../assets/img/home-one/among-one.jpg'),
                icon: 'bx bxs-badge-dollar',
                title: 'Refinancing Your Home No Doubt About Anything',
                description: 'Gain home equity by paying sit amet seeturlit sed dolor se equity'
            },
            {
                id: 5,
                image: require('../../assets/img/home-one/among-two.jpg'),
                icon: 'bx bx-bulb',
                title: 'Delivering A Great Customer Experience',
                description: 'Your home equity by paying sit amet seeturlit sed dolor se equity'
            },
            {
                id: 6,
                image: require('../../assets/img/home-one/among-three.jpg'),
                icon: 'bx bx-money',
                title: 'Trusted Home Financing Experts',
                description: 'Srt home equity by paying sit amet seeturlit sed dolor se equity'
            },
        ],
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            700: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1000: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>