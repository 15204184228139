<template>
    <div class="six-counter-area pb-70">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <ul>
                            <li>
                                <div class="progress blue">
                                    <span class="progress-left">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <span class="progress-right">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <div class="progress-value">
                                        <i class='bx bxs-bulb'></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="progress-content">
                                    <h3>90</h3>
                                    <p>Years Of Experience</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <ul>
                            <li>
                                <div class="progress yellow">
                                    <span class="progress-left">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <span class="progress-right">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <div class="progress-value">
                                        <i class='bx bxs-home-smile'></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="progress-content">
                                    <h3>69</h3>
                                    <p>VETERAN HOMES COMPLETED</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <ul>
                            <li>
                                <div class="progress pink">
                                    <span class="progress-left">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <span class="progress-right">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <div class="progress-value">
                                        <i class='bx bxs-user'></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="progress-content">
                                    <h3>56</h3>
                                    <p>VOLUNTEERS PARTICIPATED</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <ul>
                            <li>
                                <div class="progress pink">
                                    <span class="progress-left">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <span class="progress-right">
                                        <span class="progress-bar"></span>
                                    </span>
                                    <div class="progress-value">
                                        <i class='bx bx-world'></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="progress-content">
                                    <h3>19</h3>
                                    <p>CUSTOMER WORLDWIDE</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";

export default {
    name: 'Counter',
}
</script>