<template>
    <div id="contact" class="let-contact-area with-main-color">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="let-contact-form">
                        <span class="sub-title">Let’s Contact</span>
                        <h3>Contact With Us</h3>

                        <form id="contactForm">
                            <div class="row m-0">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="William">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="cavo@gmail.com">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="+4 234-543 123 678">
                                    </div>
                                </div>
        
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="your subject">
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Your Message</label>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="write your message"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="let-contact-btn">
                                        <button type="submit" class="main-default-btn">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="let-contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96873.54747595973!2d-74.01503723537454!3d40.64535309481121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24416947c2109%3A0x82765c7404007886!2sBrooklyn%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1644602327980!5m2!1sen!2sbd"></iframe>
                    </div>
                </div>
            </div>
        </div>

        <div class="let-contact-shape">
            <img src="../../assets/img/more-home/let-contact-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>