<template>
    <div>
        <NavbarThree />
        <MainBanner />
        <About />
        <ChooseYourService />
        <MortgageQuote />
        <StaffProfiles />
        <StepsProcess />
        <Funfact />
        <Faq />
        <FindOwnLoan class="find-own-loan-area" />
        <Testimonials />
        <Contact />
        <Blog />
        <SubscribeTwo class="bg-style-two" />
        <FooterThree />
    </div>
</template>

<script>
import NavbarThree from '../Layouts/NavbarThree'
import MainBanner from '../HomeEleven/MainBanner'
import About from '../HomeEleven/About'
import ChooseYourService from '../HomeEleven/ChooseYourService'
import MortgageQuote from '../HomeEleven/MortgageQuote'
import StaffProfiles from '../Common/StaffProfiles'
import StepsProcess from '../HomeEleven/StepsProcess'
import Funfact from '../HomeEleven/Funfact'
import Faq from '../HomeEleven/Faq'
import FindOwnLoan from '../Common/FindOwnLoan'
import Testimonials from '../HomeEleven/Testimonials'
import Contact from '../HomeEleven/Contact'
import Blog from '../HomeEleven/Blog'
import SubscribeTwo from '../Common/SubscribeTwo'
import FooterThree from '../Layouts/FooterThree'

export default {
    name: 'HomePageEleven',
    components: {
        NavbarThree,
        MainBanner,
        About,
        ChooseYourService,
        MortgageQuote,
        StaffProfiles,
        StepsProcess,
        Funfact,
        FindOwnLoan,
        Faq,
        Testimonials,
        Contact,
        Blog,
        SubscribeTwo,
        FooterThree,
    }
}
</script>