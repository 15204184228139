<template>
    <div>
        <NavbarOne />
        <MainBanner />
        <GetIntroduced />
        <Counter class="two-counter-area" />
        <WeHaveOffer />
        <Services />
        <OurCoreService subTitle="WHAT’S THE BEST" heading="Mortgage Programs" />
        <GetAddress />
        <OurTeam class="two-team-area" />
        <FunFact />
        <CoustomerFeedbacks />
        <Faq />
        <ContactWithUs />
        <Blog class="two-blog-area" />
        <Subscribe />
        <AmongThem subTitle="SERCH YOUR ONE" heading="Find A Loan Officer" />
        <Footer />
    </div>
</template>

<script>
import NavbarOne from '../Layouts/NavbarOne'
import MainBanner from '../HomeTwo/MainBanner'
import GetIntroduced from '../HomeTwo/GetIntroduced'
import Counter from '../Common/Counter'
import WeHaveOffer from '../HomeTwo/WeHaveOffer'
import Services from '../HomeTwo/Services'
import OurCoreService from '../Common/OurCoreService'
import GetAddress from '../HomeTwo/GetAddress'
import OurTeam from '../Common/OurTeam'
import FunFact from '../HomeTwo/FunFact'
import CoustomerFeedbacks from '../HomeTwo/CoustomerFeedbacks'
import Faq from '../HomeTwo/Faq'
import ContactWithUs from '../HomeTwo/ContactWithUs'
import Blog from '../Common/Blog'
import Subscribe from '../HomeTwo/Subscribe'
import AmongThem from '../Common/AmongThem'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageTwo',
    components: {
        NavbarOne,
        MainBanner,
        GetIntroduced,
        Counter,
        WeHaveOffer,
        Services,
        OurCoreService,
        GetAddress,
        OurTeam,
        FunFact,
        CoustomerFeedbacks,
        Faq,
        ContactWithUs,
        Blog,
        Subscribe,
        AmongThem,
        Footer,
    }
}
</script>