<template>
    <div id="home" class="five-banner-area">
        <div class="banner-shape">
            <img src="../../assets/img/home-five/banner1.png" alt="Shape">
            <img src="../../assets/img/home-five/banner-main.png" alt="Shape">
        </div>
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="banner-content">
                        <span>
                            <img src="../../assets/img/home-three/banner-icon.png" alt="Banner">
                            Welcome To Cavo
                        </span>
                        <h1>Buy A Home By <span>Confidence</span></h1>
                        <p>Reliable Sustainable Mortgage Provider</p>
                        <div class="banner-form">
                            <div class="tabs">
                                <ul class="tabs__header">
                                    <li 
                                        class="tabs__header-item" 
                                        v-for="tab in tabs" 
                                        v-on:click="selectTab (tab.id)" 
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        {{tab.name}}
                                    </li>
                                </ul>
                            </div>

                            <div class="tabs__container">
                                <ul 
                                    class="tabs__list"
                                    ref='tabsList'
                                >
                                    <li 
                                        class="tabs__list-tab"
                                        v-for="tab in tabs"
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        <form @submit.prevent="calculate">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" v-model.number="purchasePrice" placeholder="Purchase Price">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <span class="form-control pt-11">Monthly: {{ monthlyPayment.toFixed(2) }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <input type="text" placeholder="Down Payment" v-model.number="downPayment" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" v-model.number="zipCode" placeholder="Zip Code" required>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <select v-model.number="numMonths" class="form-select">
                                                            <option value="10">10 months</option>
                                                            <option value="12" selected>12 months</option>
                                                            <option value="24">24 months</option>
                                                            <option value="36">36 months</option>
                                                            <option value="48">48 months</option>
                                                            <option value="60">60 months</option>
                                                            <option value="72">72 months</option>
                                                            <option value="84">84 months</option>
                                                        </select>	
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control" v-model.number="email" placeholder="Email" required>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <button type="submit" class="btn banner-form-btn">
                                                        Calculate money
                                                    </button>
                                                </div>
                                                <div class="col-lg-6">
                                                    <a class="banner-call" href="tel:5432123451">
                                                        Call Us: 5432 123 451 (Sat - Thu)
                                                    </a>
                                                </div>
                                            </div>
                                        </form>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            activeTab: 1,
            offsetRight: 0,
            purchasePrice: '',
            downPayment: '',
            zipCode: '',
            numMonths: 12,
            email: '',
            monthlyPayment: 0,

            tabs: [
                {
                    id: 1,
                    name: 'Purchase',
                },
                {
                    id: 2,
                    name: 'Refinance',
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        },
        calculate() {
            if (!this.formValid) {
                return;
            }
            const { purchasePrice, downPayment, numMonths } = this;
            this.monthlyPayment = (purchasePrice -downPayment) / numMonths;
        },
    }, 
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        },
        formValid() {
            const { purchasePrice, downPayment, numMonths } = this;
            return (
                +purchasePrice -downPayment >= 0 &&
                +numMonths > 0
            );
        },
    }
}
</script>