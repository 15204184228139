<template>
    <div id="contact" class="let-contact-area-with-bg ptb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Let’s Contact</span>
                <h2>Contact With Us</h2>
            </div>

            <div class="let-contact-form">
                <form id="contactForm">
                    <div class="row m-0">
                        <div class="col-lg-8 col-md-12">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="William">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" id="email" class="form-control" placeholder="cavo@gmail.com">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="+4 234-543 123 678">
                                    </div>
                                </div>
        
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="your subject">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Your Message</label>
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="write your message"></textarea>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12">
                            <div class="let-contact-btn">
                                <button type="submit" class="main-default-btn">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>