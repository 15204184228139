<template>
    <div id="home" class="two-banner-wrap">
        <div class="two-banner-slider">
            <carousel 
                :autoplay="5000"
                :wrap-around="true"
            >
                <slide v-for="slide in carouselItems" :key="slide.id">
                    <div :class="slide.class">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container">
                                    <div class="banner-content">
                                        <!-- <span>
                                            <img :src="slide.image" alt="Banner">
                                            {{slide.subTitle}}
                                        </span> -->
                                        
                                        <h1>{{slide.heading}}</h1>
                                        <!-- <div class="banner-btn">
                                            <a class="cmn-banner-btn" href="#" target="_blank">
                                                <i class='bx bx-note'></i>
                                                Apply Now
                                            </a>
                                            <a class="banner-right-btn" href="#" target="_blank">
                                                <i class='bx bx-calculator'></i>
                                                Calculator
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
                <!-- <slide>
                    <div class="two-banner-area two-banner-two">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container">
                                    <div class="banner-content">
                                        <span>
                                            <img src="../../assets/img/home-two/banner-icon.png" alt="Banner">
                                            Welcome To Cavo
                                        </span>
                                        <h1>Buy A Home By Confidence</h1>
                                        <div class="banner-btn">
                                            <a class="cmn-banner-btn" href="#" target="_blank">
                                                <i class='bx bx-note'></i>
                                                Apply Now
                                            </a>
                                            <a class="banner-right-btn" href="#" target="_blank">
                                                <i class='bx bx-calculator'></i>
                                                Calculator
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide> -->
                <!-- <slide>
                    <div class="two-banner-area two-banner-three">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container">
                                    <div class="banner-content">
                                        <span>
                                            <img src="../../assets/img/home-two/banner-icon.png" alt="Banner">
                                            Welcome To Cavo
                                        </span>
                                        <h1>Mortgage Is A Great For You</h1>
                                        <div class="banner-btn">
                                            <a class="cmn-banner-btn" href="#" target="_blank">
                                                <i class='bx bx-note'></i>
                                                Apply Now
                                            </a>
                                            <a class="banner-right-btn" href="#" target="_blank">
                                                <i class='bx bx-calculator'></i>
                                                Calculator
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide> -->
                
                <template #addons>
                    <Navigation />
                </template>
            </carousel>
        </div>
<!--         <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 p-0">
                    <div class="banner-time-left">
                        <ul>
                            <li>
                                <i class='bx bx-globe'></i>
                                <span>29-14 Entin inter</span>
                                <p>New York 11105</p>
                            </li>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <span>(24 hours / 7 days)</span>
                                <a href="tel:34212213259">3421 221 3259</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 p-0">
                    <div class="banner-time-right">
                        <ul>
                            <li>
                                <i class='bx bx-timer'></i>
                                <span>Mon-Fri (8am - 6pm)</span>
                                <p>Sat-Sun (10am - 2pm)</p>
                            </li>
                            <li>
                                <i class='bx bxs-contact'></i>
                                <span>Contact us </span>
                                <a href="mailto:support@cavo.com">support@cavo.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Navigation , Slide, } from 'vue3-carousel';
import { computed } from "vue";

export default {
    name: 'MainBanner',
    components: {
        Carousel,
        Slide,
        Navigation 
    },
    data() {
        return {
            carouselItems: [
                {
                    id: 1,
                    class: 'two-banner-area two-banner-one',
                    image: require('../../assets/img/home-one/home-banner-1.jpg'),
                    subTitle: 'test',
                    heading: computed(() => this.$t('Header-Message')),

                }
            ],
            personDetails: {
                purchasePrice: '$7000', 
                estimatedMonthly: '$250',
                downPayment: '$800',
                zipCode: '73848',
                emailAddress: 'john@gmail.com',
                createdAt: new Date()
            },
        }
    },
}
</script>