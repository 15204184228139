<template>
    <div id="service" class="one-service-area ptb-100">
        <div class="container">
            <div class="one-section-title">
                <h2>{{$t('our-service')}}</h2>
            </div>
            <nav class="tabs">
                <div class="tabs__header">
                    <a 
                        class="tabs__header-item" 
                        v-for="tab in tabs" 
                        v-on:click="selectTab (tab.id)" 
                        v-bind:class="{ 'active': activeTab == tab.id }"
                        :key="tab.id"
                    >
                        <div class="service-item">
                            <i :class="tab.icon"></i>
                            <span>{{$t(tab.name)}}</span>
                        </div>
                    </a>
                </div>
            </nav>
            <div class="tabs__container">
                <div 
                    class="tabs__list"
                    ref='tabsList'
                >
                    <div 
                        class="tabs__list-tab"
                        v-for="tab in tabs"
                        v-bind:class="{ 'active': activeTab == tab.id }"
                        :key="tab.id"
                    >
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img :src="tab.image" alt="Advisor">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>{{$t(tab.title)}}</h2>
                                        <ul>
                                            <li
                                                v-for="list in tab.lists"
                                                :key="list.id"
                                            >
                                                <i :class='list.icon'></i>
                                                {{$t(list.text)}}
                                            </li>
                                        </ul>
<!--                                         <div class="advisor-link">
                                            <a href="#" target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";

export default {
    name: 'OurCoreService',
    props: ['subTitle', 'heading'],
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    icon: 'bx bxs-building-house',
                    name: computed(() =>  this.$t('property-law')),
                    image: require('../../assets/img/home-one/advisor.jpg'),
                    title: computed(() => this.$t('property-law')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('property-settlement')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('property-due-diligence')),
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('property-refinance')),
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('property-subdivision')),
                        },
                        {
                            id: 5,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('property-consent')),
                        },
                        {
                            id: 6,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('property-keys')),
                        }
                    ]
                },
                {
                    id: 2,
                    icon: 'bx bxs-dollar-circle',
                    name: computed(() => this.$t('commercial-law')),
                    image: require('../../assets/img/home-one/advisor2.jpg'),
                    title: computed(() => this.$t('commercial-law')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('commercial-purchase')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('commercial-rental')),
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('commercial-share')),
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('commercial-merge')),
                        },
                        {
                            id: 5,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('commercial-contract')),
                        },
                    ]
                },
                {
                    id: 3,
                    icon: 'bx bxs-wrench',
                    name: computed(() => this.$t('family-law')),
                    image: require('../../assets/img/home-one/advisor3.jpg'),
                    title: computed(() => this.$t('family-law')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('family-property')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('family-divorce')),
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('family-guardianship')),
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('family-prenuptial')),
                        },
                        {
                            id: 5,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('family-restraining-order')),
                        }
                    ]
                },
                {
                    id: 4,
                    icon: 'bx bxs-hand-up',
                    name: computed(() => this.$t('immigration-law')),
                    image: require('../../assets/img/home-one/advisor4.jpg'),
                    title:  computed(() => this.$t('immigration-law')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('immigration-visa')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('immigration-family-union')),
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('immigration-overstay')),
                        },
                        {
                            id: 4,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('immigration-deportation')),
                        },
                        {
                            id: 5,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('immigration-complaint')),
                        },
                        {
                            id: 6,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('immigration-judicial-review')),
                        },
                    ]
                },
                {
                    id: 5,
                    icon: 'bx bxs-home-circle',
                    name: computed(() => this.$t('trust')),
                    image: require('../../assets/img/home-one/advisor5.jpg'),
                    title: computed(() => this.$t('trust')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('trust-family-trust')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('trust-will')),
                        }
                    ]
                },
                {
                    id: 6,
                    icon: 'bx bxs-user-circle',
                    name: computed(() => this.$t('employment-law')),
                    image: require('../../assets/img/home-one/advisor6.jpg'),
                    title: computed(() => this.$t('employment-law')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('employment-contract')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('employment-dispute')),
                        }
                    ]
                },
                {
                    id: 7,
                    icon: 'bx bxs-user-circle',
                    name: computed(() => this.$t('dispute')),
                    image: require('../../assets/img/home-one/dispute.jpg'),
                    title: computed(() => this.$t('dispute')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('dispute-civil')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('dispute-commercial')),
                        }
                    ]
                },
                {
                    id: 8,
                    icon: 'bx bxs-user-circle',
                    name: computed(() => this.$t('liability')),
                    image: require('../../assets/img/home-one/advisor6.jpg'),
                    title: computed(() => this.$t('liability')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('liability-personal')),
                        },
                        {
                            id: 2,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('liability-commercial')),
                        },
                        {
                            id: 3,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('liability-bankruptcy')),
                        }
                    ]
                },
                {
                    id: 9,
                    icon: 'bx bxs-user-circle',
                    name: computed(() => this.$t('notary')),
                    image: require('../../assets/img/home-one/advisor6.jpg'),
                    title: computed(() => this.$t('notary')),
                    shortDesc: '',
                    lists: [
                        {
                            id: 1,
                            icon: 'bx bxs-chevrons-right',
                            text: computed(() => this.$t('notary-international')),
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    }, 
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        }
    }
}
</script>