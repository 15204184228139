<template>
    <div class="process-style-area-without-bg ptb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Steps & Process</span>
                <h2>Go Beyond With Cavo</h2>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="process-style-accordion">
                        <div class="accordion" id="ProcessAccordion">
                            <div class="accordion-item">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span>01</span> Appraisal
                                </button>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#ProcessAccordion">
                                    <div class="accordion-body">
                                        <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                        <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span>02</span> Conversation
                                </button>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                    <div class="accordion-body">
                                        <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                        <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span>03</span> Application
                                </button>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                    <div class="accordion-body">
                                        <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                        <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <span>04</span> Documents
                                </button>
                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                    <div class="accordion-body">
                                        <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                        <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <span>05</span> Upfront Underwriting
                                </button>
                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                    <div class="accordion-body">
                                        <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                        <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="process-style-wrap-image">
                        <img src="../../assets/img/more-home/process/process-2.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepsProcess'
}
</script>