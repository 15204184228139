<template>
    <div id="home" class="banner-buy-area">
        <div class="container-fluid">
            <div class="banner-buy-content">
                <span class="sub-title">Welcome to Cavo</span>
                <h1>Buy A Home By Confidence</h1>
                <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tr inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>

                <ul class="banner-buy-btn">
                    <li>
                        <a href="#" class="main-default-btn">Apply Now</a>
                    </li>
                    <li>
                        <a href="#" class="main-optional-btn">Calculator</a>
                    </li>
                </ul>
            </div> 

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bx-time'></i>
                        </div>
                        <p>
                            <span>Mon-Fri (8am - 6pm)</span>
                            <span>Sat-Sun (10am - 2pm)</span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bxs-contact'></i>
                        </div>
                        <p>
                            <span>Contact us</span>
                            <span><a href="mailto:support@cavo.com">support@cavo.com</a></span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <p>
                            <span>(24 hours / 7 days)</span>
                            <span><a href="tel:4234543123678">+4 234-543 123 678</a></span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="banner-buy-info-card">
                        <div class="icon">
                            <i class='bx bx-world'></i>
                        </div>
                        <p>
                            <span>9675 Grant Ave.</span>
                            <span>Chicago Heights, IL 60411</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>