<template>
    <div id="blog" class="blog-style-area pt-100 pb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">News & Blog</span>
                <h2>Featured News And Insights</h2>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="blog-style-left-card">
                        <div class="blog-image">
                            <a href="#"><img src="../../assets/img/more-home/blog/blog-1.jpg" alt="image"></a>

                            <div class="tag">Mortgage</div>
                        </div>
                        <div class="blog-content">
                            <ul class="post-meta d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/more-home/user-1.jpg" class="rounded-circle" alt="image">
                                        <span>Esther Cooper</span>
                                    </div>
                                </li>

                                <li>
                                    <i class='bx bxs-calendar'></i> December 27, 2022
                                </li>

                                <li>
                                    <i class='bx bx-message-dots'></i> No Comments
                                </li>
                            </ul>
                            <h3>
                                <a href="#">Refinancing Your Home No Doubt About Anything</a>
                            </h3>
                            <p>Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor lectus nibh...</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="blog-style-right-card">
                        <div class="card-box">
                            <div class="blog-content">
                                <div class="tag">Privacy</div>
                                <ul class="post-meta">
                                    <li>
                                        <i class='bx bxs-calendar'></i> December 27, 2022
                                    </li>

                                    <li>
                                        <i class='bx bx-message-dots'></i> No Comments
                                    </li>
                                </ul>
                                <h3>
                                    <a href="#">What Means Your Mortgage And 5 Ways To Improve</a>
                                </h3>
                                <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus...</p>
                            </div>
                        </div>

                        <div class="card-box">
                            <div class="blog-content">
                                <div class="tag">Taechnoloby</div>
                                <ul class="post-meta">
                                    <li>
                                        <i class='bx bxs-calendar'></i> December 27, 2022
                                    </li>

                                    <li>
                                        <i class='bx bx-message-dots'></i> No Comments
                                    </li>
                                </ul>
                                <h3>
                                    <a href="#">5 Ways To build Yourself By Hard Working</a>
                                </h3>
                                <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>