<template>
    <div class="six-nearly-area ptb-100">
        <div class="container">
            <div class="one-section-title three-section-title">
                <span class="sub-title"># CHOOSE YOUR SERVICE</span>
                <h2>Nearly We Have Been Trying To Provide Those</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 pr-0">
                    <div class="nearly-content">
                        <div class="tabs">
                            <ul class="tabs__header">
                                <li 
                                    class="tabs__header-item" 
                                    v-for="tab in tabs" 
                                    v-on:click="selectTab (tab.id)" 
                                    v-bind:class="{ 'active': activeTab == tab.id }"
                                    :key="tab.id"
                                >
                                    {{tab.name}}
                                </li>
                            </ul>
                        </div>
                        <div class="tabs__container">
                            <ul 
                                class="tabs__list"
                                ref='tabsList'
                            >
                                <li 
                                    class="tabs__list-tab"
                                    v-for="tab in tabs"
                                    v-bind:class="{ 'active': activeTab == tab.id }"
                                    :key="tab.id"
                                >
                                    <h3>Auto Dealer Solutions</h3>
                                    <p>Lorem ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
                                    <ul>
                                        <li>
                                            <i class='bx bx-check'></i>
                                            Affordable Car Calculater
                                        </li>
                                        <li>
                                            <i class='bx bx-check'></i>
                                            Auto Financing Center
                                        </li>
                                        <li>
                                            <i class='bx bx-check'></i>
                                            Valley All Access Rewards
                                        </li>
                                    </ul>
                                    <div class="nearly-btn">
                                        <a class="cmn-banner-btn" href="#" target="_blank">
                                            Get Quote
                                            <i class='bx bx-right-arrow-alt'></i>
                                        </a>
                                        <a class="nearly-btn" href="#" target="_blank">
                                            Learn More
                                            <i class='bx bx-right-arrow-alt'></i>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pl-0">
                    <div class="nearly-img">
                        <img src="../../assets/img/home-six/nearly-main.jpg" alt="Nearly">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseYourService',
    data (){
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    name: 'Dealer Solutions',
                },
                {
                    id: 2,
                    name: 'Plan Financing',
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
        let tabsList = this.$refs.tabsList
        this.activeTab = id
        this.offsetRight = tabsList.clientWidth * (id - 1)
        tabsList.style.right = this.offsetRight + 'px'
        }
    }, 
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        }
    }
}
</script>