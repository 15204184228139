<template>
    <nav :class="['navbar navbar-expand-md navbar-light', { 'is-sticky': isSticky }]">
        <router-link class="navbar-brand" to="/">
            <img src="../../assets/img/pcw_logo.png" alt="Logo">
        </router-link>

        <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ 'active': button_active_state }" v-on:click="button_active_state = !button_active_state">
            <i class='bx bx-menu'></i>
            <i class='bx bx-x'></i>
        </div>

        <div class="collapse navbar-collapse" :class="{ toggler: active }">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('home')" href="/">{{ $t('Menu-Home') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('about')" href="/">{{ $t('Menu-AboutUs') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('service')" href="/">{{ $t('Menu-OurServices') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('team')" href="/">{{ $t('Menu-Team') }}</a>
                </li>
                <!--                 <li class="nav-item">
                    <a class="nav-link" @click="scroll('blog')" href="javascript:void(0)">Blog</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('contact')" href="/home/">{{ $t('Menu-Contact') }}</a>
                </li>
            </ul>
        </div>
        <div class="side-nav">
            <a class="side-nav-left" href="/onlinepayment">{{$t('OnlinePayment')}}</a>
        </div>
        <LocaleSwitcher />
    </nav>

                    <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3 col-sm-6">
                            <div>
                                <!-- The Stripe Buy Button will be inserted here -->
                                <div ref="stripeButtonContainer"></div>
                            </div>
                        </div>
                            <br />
                            </div>

                </div>
            <Footer />

</template>


<script>
import NavbarOne from '../Layouts/NavbarOne'
import Footer from '../Layouts/FooterPCW'
import LocaleSwitcher from "../LocaleSwitcher.vue";


export default {
    name: 'OnlinePayment',
    components: {
        NavbarOne,
        Footer,
        LocaleSwitcher,
    },
    mounted() {
      // Create a script element
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/buy-button.js';
      script.async = true;
      script.onload = this.insertStripeButton;
  
      // Append the script to the document head
      document.head.appendChild(script);
    },
    methods: {
      insertStripeButton() {
        // Create the stripe-buy-button element
        const stripeButton = document.createElement('stripe-buy-button');
        stripeButton.setAttribute('buy-button-id', 'buy_btn_1PMRroEAtM7uWQQFGd45EODm');
        stripeButton.setAttribute('publishable-key', 'pk_live_51PIOkzEAtM7uWQQFgCykkmFuSAGmL12tdYMv2KZqx0LpETPqxG0aEAonRXu40cXDPqKybxr14yFj7Hc0c71IPPDP00MYj9kITA');
  
        // Append the stripe-buy-button to the container
        this.$refs.stripeButtonContainer.appendChild(stripeButton);
      }
    }
  };

</script>

<style scoped>
/* Add any relevant styles here */
</style>
