<template>
    <div class="one-subscribe-area five-subscribe-area six-subscribe-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="subscribe-wrap">
                        <h2>Don't Miss Our Update Use This Subscription</h2>

                        <form class="newsletter-form" data-toggle="validator">
                            <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL">
    
                            <button class="btn subscribe-btn" type="submit">
                                Subscribe now
                            </button>
                        </form>
                        <div class="sub-img">
                            <img src="../../assets/img/home-six/sub-main.png" alt="Subscribe">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Subscription'
}
</script>