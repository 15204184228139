<template>
    <div>
        <TopHeaderTwo />
        <NavbarOne />
        <MainBanner />
        <Time />
        <AboutUs />
        <WhatsYouExpect />
        <OurStepAndProcess />
        <Counter class="three-counter-area" />
        <TeamLeadership />
        <CategoriesOfLoan />
        <FrequentlyQuestion />
        <ContactWithUs />
        <Review />
        <Join />
        <Blog class="two-blog-area" />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import TopHeaderTwo from '../Layouts/TopHeaderTwo'
import NavbarOne from '../Layouts/NavbarOne'
import MainBanner from '../HomeThree/MainBanner'
import Time from '../HomeThree/Time'
import AboutUs from '../HomeThree/AboutUs'
import WhatsYouExpect from '../HomeThree/WhatsYouExpect'
import OurStepAndProcess from '../HomeThree/OurStepAndProcess'
import Counter from '../Common/Counter'
import TeamLeadership from '../HomeThree/TeamLeadership'
import CategoriesOfLoan from '../Common/CategoriesOfLoan'
import FrequentlyQuestion from '../HomeThree/FrequentlyQuestion'
import ContactWithUs from '../HomeThree/ContactWithUs'
import Review from '../HomeThree/Review'
import Join from '../HomeThree/Join'
import Blog from '../Common/Blog'
import Subscribe from '../Common/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageThree',
    components: {
        TopHeaderTwo,
        NavbarOne,
        MainBanner,
        Time,
        AboutUs,
        WhatsYouExpect,
        OurStepAndProcess,
        Counter,
        TeamLeadership,
        CategoriesOfLoan,
        FrequentlyQuestion,
        ContactWithUs,
        Review,
        Join,
        Blog,
        Subscribe,
        Footer,
    }
}
</script>