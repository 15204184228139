import { createWebHistory, createRouter } from "vue-router";

import HomePageOne from "../components/Pages/HomePageOne";
import HomePageTwo from "../components/Pages/HomePageTwo";
import HomePageThree from "../components/Pages/HomePageThree";
import HomePageFour from "../components/Pages/HomePageFour";
import HomePageFive from "../components/Pages/HomePageFive";
import HomePageSix from "../components/Pages/HomePageSix";
import HomePageSeven from "../components/Pages/HomePageSeven";
import HomePageEight from "../components/Pages/HomePageEight";
import HomePageNine from "../components/Pages/HomePageNine";
import HomePageTen from "../components/Pages/HomePageTen";
import HomePageEleven from "../components/Pages/HomePageEleven";
import HomePageTwelve from "../components/Pages/HomePageTwelve";
import StaffPing from "../components/Pages/StaffPing";
import StaffSean from "../components/Pages/StaffSean";
import StaffDonnell from "../components/Pages/StaffDonnell";
import StaffAnnette from "../components/Pages/StaffAnnette";
import StaffDrKey from "../components/Pages/StaffDrKey";
import StaffDoug from "../components/Pages/StaffDoug";
import StaffJeff from "../components/Pages/StaffJeff";
import OnlinePayment from "../components/Pages/OnlinePayment";

const routes = [
  { path: "/", name: "HomePageOne", component: HomePageOne },
  { path: "/home-two", name: "HomePageTwo", component: HomePageTwo },
  { path: "/home-three", name: "HomePageThree", component: HomePageThree },
  { path: "/home-four", name: "HomePageFour", component: HomePageFour },
  { path: "/home-five", name: "HomePageFive", component: HomePageFive },
  { path: "/home-six", name: "HomePageSix", component: HomePageSix },
  { path: "/home-seven", name: "HomePageSeven", component: HomePageSeven },
  { path: "/home-eight", name: "HomePageEight", component: HomePageEight },
  { path: "/home-nine", name: "HomePageNine", component: HomePageNine },
  { path: "/home-ten", name: "HomePageTen", component: HomePageTen },
  { path: "/home-eleven", name: "HomePageEleven", component: HomePageEleven },
  { path: "/home-twelve", name: "HomePageTwelve", component: HomePageTwelve },
  { path: "/staff/ping", name: "StaffPing", component: StaffPing },
  { path: "/staff/sean", name: "StaffSean", component: StaffSean },
  { path: "/staff/donnell", name: "StaffDonnell", component: StaffDonnell },
  { path: "/staff/annette", name: "StaffAnnette", component: StaffAnnette },
  { path: "/staff/drkey", name: "StaffDrKey", component: StaffDrKey },
  { path: "/staff/doug", name: "StaffDoug", component: StaffDoug },
  { path: "/staff/jeff", name: "StaffJeff", component: StaffJeff },
  { path: "/onlinepayment", name: "OnlinePayment", component: OnlinePayment },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
