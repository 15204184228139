<template>
    <div class="overview-style-area">
        <div class="container">
            <div class="overview-style-inner-box">
                <div class="content">
                    <h3>Take The First Step Toward The Right <span>Mortgage</span></h3>
                
                    <ul class="overview-btn">
                        <li>
                            <a href="#" class="main-default-btn">Career Opportunity</a>
                        </li>
                        <li>
                            <a href="#" class="main-optional-btn">Join Community</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="overview-style-shape-1">
            <img src="../../assets/img/more-home/overview-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overview'
}
</script>