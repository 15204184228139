<template>
    <div class="two-lover-area five-lover-area six-lover-area ptb-100">
        <div class="container-fluid p-0">
            <div class="one-section-title three-section-title">
                <span class="sub-title"># TESTIMONIAL</span>
                <h2>We Serve Our Clients Best Of Capacity</h2>
            </div>
            <div class="six-lover-slider">
                <carousel 
                    :autoplay="5000"
                    :wrap-around="true"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="lover-item">
                            <ul>
                                <li>
                                    <div class="lover-content">
                                        <h3>{{slide.name}}</h3>
                                        <span>{{slide.position}}</span>
                                        <p>{{slide.description}}</p>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star checked'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star checked'></i>
                                            </li>
                                        </ul>
                                        <div class="lover-icon">
                                            <i class='bx bxs-quote-left'></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </slide>
                    
                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'Testimonial',
    components: {
        Carousel,
        Slide,
        Pagination
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                name: 'Donney Jon',
                position: 'Perisian Org',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'
            },
            {
                id: 2,
                name: 'Oli Rubion',
                position: 'Entoin Ect',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'
            },
            {
                id: 3,
                name: 'Sanaik Tubi',
                position: 'Furnishhome, Internation',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'
            },
            {
                id: 4,
                name: 'Donney Jon',
                position: 'Perisian Org',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'
            },
            {
                id: 5,
                name: 'Oli Rubion',
                position: 'Entoin Ect',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'
            },
            {
                id: 6,
                name: 'Sanaik Tubi',
                position: 'Furnishhome, Internation',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'
            },
        ],
        // carousel settings
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            600: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1000: {
                itemsToShow: 3.9,
                snapAlign: 'center',
            },
        },
    }),
})
</script>