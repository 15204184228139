<template>
    <div class="one-subscribe-area two-subscribe-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="subscribe-wrap">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <h2>Do you want to get update what’s upcoming?</h2>
                            </div>

                            <div class="col-lg-6">
                                <form class="newsletter-form" data-toggle="validator">
                                    <input type="email" class="form-control" placeholder="Enter your email">
            
                                    <button class="btn subscribe-btn" type="submit">
                                        Subscribe now
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Subscribe'
}
</script>