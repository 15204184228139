<template>
    <div class="why-choose-us-area ptb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Why Choose Us</span>
                <h2>Among Them We’re</h2>
            </div>

            <div class="why-choose-us-slider">
                <carousel 
                    :autoplay="5000"
                    :wrap-around="true"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="why-choose-us-card">
                            <div class="choose-image">
                                <img :src="slide.image" alt="image">
                            </div>
                            <div class="choose-content">
                                <h3>{{slide.title}}</h3>
                                <p>{{slide.desc}}</p>
                                <a href="#" class="choose-btn">Read More</a>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'WhyChooseUs',
    components: {
        Carousel,
        Slide,
        Pagination,
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/img/more-home/choose/choose-1.jpg'),
                title: 'Realtor Giving Keys To The Happy New Owner',
                desc: 'Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.'
            },
            {
                id: 2,
                image: require('../../assets/img/more-home/choose/choose-2.jpg'),
                title: 'Refinancing Your Home No Doubt About Anything',
                desc: 'Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.'
            },
            {
                id: 3,
                image: require('../../assets/img/more-home/choose/choose-3.jpg'),
                title: 'Delivering A Great Customer Experience',
                desc: 'Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.'
            },
            {
                id: 4,
                image: require('../../assets/img/more-home/choose/choose-1.jpg'),
                title: 'Realtor Giving Keys To The Happy New Owner',
                desc: 'Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.'
            },
            {
                id: 5,
                image: require('../../assets/img/more-home/choose/choose-2.jpg'),
                title: 'Refinancing Your Home No Doubt About Anything',
                desc: 'Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.'
            },
        ],
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>