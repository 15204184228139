<template>
  <div id="about" class="get-introduced-area pt-100">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="get-introduced-content">
            <span class="sub-title">Get Introduced</span>
            <h3>Our Mission Is To Become The Best Mortgage</h3>
            <p>
              Mauris blandit aliquet elit eget tincidunt nibh pulvinar. Praesent
              sapien massa convallis pellentesque nec egestas non nisi curabitur
              non nulla sit nisl tempus convallis quis ac lectus.
            </p>

            <div class="row justify-content-center">
              <div class="col-lg-6 col-sm-6">
                <div class="introduced-inner-box">
                  <div class="icon">
                    <i class="bx bx-user"></i>
                  </div>
                  <span>Speak With Loan Officer</span>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="introduced-inner-box">
                  <div class="icon">
                    <i class="bx bx-certification"></i>
                  </div>
                  <span>Tools Resource</span>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="introduced-inner-box">
                  <div class="icon">
                    <i class="bx bxs-hand-right"></i>
                  </div>
                  <span>Get Started With Cavo</span>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="introduced-inner-box">
                  <div class="icon">
                    <i class="bx bxs-home-circle"></i>
                  </div>
                  <span>See Home Pricing</span>
                </div>
              </div>
            </div>

            <div class="introduced-counter-item">
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-4 col-sm-6">
                  <div class="counter-card">
                    <h3>20</h3>
                    <p>Years Of Experience</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6">
                  <div class="counter-card">
                    <h3>98</h3>
                    <p>Volunteers Participated</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6">
                  <div class="counter-card">
                    <h3>90</h3>
                    <p>Customer Worldwide</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="get-introduced-image"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetIntroduced",
};
</script>
