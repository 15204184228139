<template>
    <div class="buying-home-area ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-5 col-md-12">
                    <div class="buying-home-image">
                        <img src="../../assets/img/more-home/buying-home/buying-home.jpg" alt="image">

                        <div class="counter-box">
                            <h4>56+</h4>
                            <span>Veteran Homes Completed</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="buying-home-content">
                        <span class="sub-title">Buying A Home</span>
                        <h3>We Are Here To Help You</h3>
                        <p>Sed porttitor lectus nibh quisque velit nisi pretium ut lacinia in elementum id enim. Mauris blandit aliquet elit eget tincidunt nibh pulvinar convallis at tellus.</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <div class="buying-inner-box">
                                    <div class="icon">
                                        <i class='bx bx-check'></i>
                                    </div>
                                    <h4>Make it Your Dream</h4>
                                    <p>When you own your own homet seeturlit sed dolor sit am</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="buying-inner-box">
                                    <div class="icon">
                                        <i class='bx bx-check'></i>
                                    </div>
                                    <h4>Create A Place</h4>
                                    <p>You can grow in your new homeit amet seeturlit sed dolor sit</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="buying-inner-box">
                                    <div class="icon">
                                        <i class='bx bx-check'></i>
                                    </div>
                                    <h4>Be A Part Of Community</h4>
                                    <p>Buying a home is an investmemet seeturlit sed dolo ameol</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="buying-inner-box">
                                    <div class="icon">
                                        <i class='bx bx-check'></i>
                                    </div>
                                    <h4>Pay Principal</h4>
                                    <p>Gain home equity by paying sit amet seeturlit sed dolor sit</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="buying-inner-box">
                                    <div class="icon">
                                        <i class='bx bx-check'></i>
                                    </div>
                                    <h4>Get Tax Benefits</h4>
                                    <p>Take advantage of tax benefitsit amet seeturlit sed dolor sit</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="buying-inner-box">
                                    <div class="icon">
                                        <i class='bx bx-check'></i>
                                    </div>
                                    <h4>Build Credit</h4>
                                    <p>Our plan dolor sit amet seeturlit sed dolor sit ameolor sit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyingHome'
}
</script>