<template>
    <div>
        <header class="main-header-area">
            <TopHeaderThree />
            <NavbarFour />
        </header>
        <MainBanner />
        <About />
        <ChooseYourService />
        <StepsProcess />
        <Funfact />
        <Services />
        <MortgageQuote />
        <OurStaff class="pt-100" />
        <Faq />
        <Information />
        <FindOwnLoan class="find-own-loan-area-without-color" />
        <Testimonials />
        <Blog />
        <SubscribeTwo />
        <FooterThree />
    </div>
</template>

<script>
import TopHeaderThree from '../Layouts/TopHeaderThree'
import NavbarFour from '../Layouts/NavbarFour'
import MainBanner from '../HomeTwelve/MainBanner'
import About from '../HomeTwelve/About'
import ChooseYourService from '../HomeTwelve/ChooseYourService'
import StepsProcess from '../HomeTwelve/StepsProcess'
import Funfact from '../HomeTwelve/Funfact'
import Services from '../HomeTwelve/Services'
import MortgageQuote from '../HomeTwelve/MortgageQuote'
import OurStaff from '../Common/OurStaff'
import Faq from '../HomeTwelve/Faq'
import Information from '../HomeTwelve/Information'
import FindOwnLoan from '../Common/FindOwnLoan'
import Testimonials from '../HomeTwelve/Testimonials'
import Blog from '../HomeTwelve/Blog'
import SubscribeTwo from '../Common/SubscribeTwo'
import FooterThree from '../Layouts/FooterThree'

export default {
    name: 'HomePageTwelve',
    components: {
        TopHeaderThree,
        NavbarFour,
        MainBanner,
        About,
        ChooseYourService,
        StepsProcess,
        Funfact,
        Services,
        MortgageQuote,
        OurStaff,
        FindOwnLoan,
        Faq,
        Information,
        Testimonials,
        Blog,
        SubscribeTwo,
        FooterThree,
    }
}
</script>