<template>
    <div class="five-quote-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-8 p-0">
                    <div class="row quote-wrap align-items-center m-0">
                        <div class="col-lg-5">
                            <div class="quote-content">
                                <div class="one-section-title three-section-title">
                                    <span class="sub-title"># GET A QUOTE</span>
                                    <h2>Get Mortgage Quote If You have any Doubt It</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="quote-btn">
                                <a class="quote-btn-left" href="#" target="_blank">
                                    <i class='bx bxs-quote-right'></i>
                                    Get a quote
                                </a>
                                <a class="quote-btn-right" href="#" target="_blank">
                                    <i class='bx bx-donate-heart'></i>
                                    Join community
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="quote-img">
                        <img src="../../assets/img/home-five/quote.png" alt="Quote">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Quote'
}
</script>