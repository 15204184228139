<template>
    <div class="get-address-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="get-address-content">
                        <span class="sub-title">Get Address</span>
                        <h3>Here Is Our Roadmap</h3>

                        <div class="inner-box">
                            <span class="number">1</span>
                            <h4>Flexible appointments</h4>
                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed</p>
                        </div>
                        <div class="inner-box">
                            <span class="number">2</span>
                            <h4>Comparing the entire market</h4>
                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed</p>
                        </div>
                        <div class="inner-box">
                            <span class="number">3</span>
                            <h4>Online tools</h4>
                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="get-address-form">
                        <h3>Find A Loan Officer</h3>
                        
                        <div class="loan-check-box">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                                <label class="form-check-label" for="exampleRadios1">
                                    Real Estate Agents
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                <label class="form-check-label" for="exampleRadios2">
                                    Loan Officer
                                </label>
                            </div>
                        </div>

                        <form>
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" class="form-control" placeholder="Darlene">
                            </div>
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" class="form-control" placeholder="Robertson">
                            </div>
                            <div class="form-group">
                                <label>License State</label>
                            
                                <div class="select-box">
                                    <select>
                                        <option>License State</option>
                                        <option>Canada</option>
                                        <option>Japan</option>
                                        <option>Germany</option>
                                        <option>Switzerland</option>
                                        <option>Australia</option>
                                    </select>
                                </div>
                            </div>

                            <div class="loan-button">
                                <button type="submit" class="default-btn">Search Your Result</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetAddress'
}
</script>