<template>
    <div>
        <div id="home" class="main-banner-area">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Mortgage Is A Great For You</h1>
                            <div class="banner-popup align-items-center">
                                <a 
                                    href="javascript:void(0)" 
                                    class="video-btn"
                                    v-on:click="isPopupMethod(isPopup)"
                                >
                                    <i class='bx bx-play' ></i>
                                    <span>Intro With Company</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-form-wrap">
                            <h3>Calculate your Mortgage</h3>
                            
                            <form>
                                <div class="form-group">
                                    <label>Total Amount</label>
                                
                                    <div class="select-box">
                                        <select>
                                            <option>$50,000</option>
                                            <option>$60,000</option>
                                            <option>$70,000</option>
                                            <option>$80,000</option>
                                            <option>$90,000</option>
                                            <option>$40,000</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Interest Rate</label>
                                
                                    <div class="select-box">
                                        <select>
                                            <option>5%</option>
                                            <option>10%</option>
                                            <option>15%</option>
                                            <option>20%</option>
                                            <option>25%</option>
                                            <option>30%</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Down Payment</label>
                                
                                    <div class="select-box">
                                        <select>
                                            <option>$50,000</option>
                                            <option>$60,000</option>
                                            <option>$70,000</option>
                                            <option>$80,000</option>
                                            <option>$90,000</option>
                                            <option>$40,000</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Mortgage Period</label>
                                
                                    <div class="select-box">
                                        <select>
                                            <option>15 Years</option>
                                            <option>16 Years</option>
                                            <option>17 Years</option>
                                            <option>18 Years</option>
                                            <option>19 Years</option>
                                            <option>20 Years</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="calculat-button">
                                    <button type="submit" class="default-btn">Calculate Mortgage</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>