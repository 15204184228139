<template>
    <div id="about" class="about-style-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-style-image-wrap">
                        <img src="../../assets/img/more-home/about/about-2.png" alt="image">

                        <div class="certified-image">
                            <img src="../../assets/img/more-home/about/certified.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-style-wrap-content">
                        <span class="sub-title">About Our Company</span>
                        <h3>We Are Fully Dedicated To Support You</h3>
                        <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>
                        
                        <div class="about-list-tab">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="about-1-tab" data-bs-toggle="tab" href="#about-1" role="tab" aria-controls="about-1">Our Mission</a>
                                </li>
        
                                <li class="nav-item">
                                    <a class="nav-link" id="about-2-tab" data-bs-toggle="tab" href="#about-2" role="tab" aria-controls="about-2">Our Vision </a>
                                </li>
                            </ul>
        
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="about-1" role="tabpanel">
                                    <div class="content">
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit.</p>

                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        </ul>
                                    </div>
                                </div>
        
                                <div class="tab-pane fade" id="about-2" role="tabpanel">
                                    <div class="content">
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit.</p>

                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-style-shape-1">
            <img src="../../assets/img/more-home/about/about-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>