<template>
    <div class="one-contact-area three-contact-area six-contact-area">
        <div class="container">
            <div class="contact-content">
                <form id="contactForm">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title"># LET’S CONTACT</span>
                        <h2>Contact With Us</h2>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-user'></i>
                                </label>
                                <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-mail-send'></i>
                                </label>
                                <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-phone-call'></i>
                                </label>
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>
                                    <i class='bx bxs-edit-alt'></i>
                                </label>
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12">
                            <div class="form-group">
                                <label>
                                    <i class='bx bx-message-square-detail'></i>
                                </label>
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Message"></textarea>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12">
                            <div class="row m-0">
                                <div class="col-lg-12 pl-0">
                                    <button type="submit" class="contact-btn btn">
                                        Send Message
                                        <i class='bx bx-right-arrow-alt'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactWithUs'
}
</script>