<template>
    <div id="blog" class="one-blog-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">NEWS & BLOG</span>
                <h2>Featured News And Insights</h2>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a href="#" target="_blank">
                                <img src="../../assets/img/home-one/blog1.jpg" alt="Blog">
                            </a>
                            <span>Mortgage</span>
                        </div>
                        <div class="blog-bottom">
                            <ul>
                                <li>
                                    <i class='bx bx-time-five'></i>
                                    09 April 2022
                                </li>
                                <li>
                                    <i class='bx bx-comment-detail'></i>
                                    0 Comment
                                </li>
                            </ul>
                            <h3>
                                <a href="#" target="_blank">What Means Your Mortgage And 5 Ways To Improve</a>
                            </h3>
                            <img src="../../assets/img/home-one/blog1.png" alt="Blog">
                            <p><a href="#" target="_blank">Zain jonson</a>, Author</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a href="#" target="_blank">
                                <img src="../../assets/img/home-one/blog2.jpg" alt="Blog">
                            </a>
                            <span>Mortgage</span>
                        </div>
                        <div class="blog-bottom">
                            <ul>
                                <li>
                                    <i class='bx bx-time-five'></i>
                                    10 April 2022
                                </li>
                                <li>
                                    <i class='bx bx-comment-detail'></i>
                                    0 Comment
                                </li>
                            </ul>
                            <h3>
                                <a href="#" target="_blank">8 Ways To Make Your Home A Smart Home</a>
                            </h3>
                            <img src="../../assets/img/home-one/blog2.png" alt="Blog">
                            <p><a href="#" target="_blank">Movin Jex</a>, Author</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a href="#" target="_blank">
                                <img src="../../assets/img/home-one/blog3.jpg" alt="Blog">
                            </a>
                            <span>Mortgage</span>
                        </div>
                        <div class="blog-bottom">
                            <ul>
                                <li>
                                    <i class='bx bx-time-five'></i>
                                    11 April 2022
                                </li>
                                <li>
                                    <i class='bx bx-comment-detail'></i>
                                    0 Comment
                                </li>
                            </ul>
                            <h3>
                                <a href="#" target="_blank">5 Ways To build Yourself By Hard Working</a>
                            </h3>
                            <img src="../../assets/img/home-one/blog3.png" alt="Blog">
                            <p><a href="#" target="_blank">Jems</a>, Author</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>