<template>
    <div id="contact" class="let-contact-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="let-contact-form">
                        <span class="sub-title">{{ $t('contact') }}</span>
                        <h3>{{ $t('contact-us') }}</h3>

                        <form id="contactForm" action="https://formspree.io/f/xdovnelv" method="POST">
                            <div class="row m-0">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-user'></i>
                                        </label>
                                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-mail-send'></i>
                                        </label>
                                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-phone-call'></i>
                                        </label>
                                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
        
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bxs-edit-alt'></i>
                                        </label>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>
                                            <i class='bx bx-message-square-detail'></i>
                                        </label>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="Message"></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="row m-0">
                                        <div class="col-sm-4 col-lg-4 pl-0">
                                            <button type="submit" class="contact-btn">
                                                <i class='bx bx-plus'></i>
                                                Send Message
                                            </button>
                                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                                            <div class="clearfix"></div>
                                        </div>
<!--                                         <div class="col-sm-8 col-lg-8">
                                            <div class="contact-emergency">
                                                <i class='bx bx-phone-call'></i>
                                                <span>Emergency Contact</span>
                                                <a href="tel:+44554348554">+445 543 48554</a>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="let-contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.0268675534508!2d174.9266093!3d-36.913622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4b46fb71befd%3A0x371b0da941342d69!2zUENXIExBVyBMSU1JVEVEICYgTm90YXJ5IC0gRWFzdCBPZmZpY2UgKOmZiOiQjeW-i-W4iOihjOWbvemZheWFrOivgeS4nOWMuuWKnuWFrOWupO-8iQ!5e0!3m2!1sen!2snz!4v1666953542713!5m2!1sen!2snz" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>