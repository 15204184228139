<template>
    <div>
        <div id="home" class="three-banner-area six-banner-area">
            <div class="banner-img">
                <img src="../../assets/img/home-six/banner-shape.png" alt="Banner">
            </div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">
                            <span>
                                <img src="../../assets/img/home-three/banner-icon.png" alt="Banner">
                                Cavo.com
                            </span>
                            <h1>Cavo Residential <span>Mortgage</span></h1>
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" href="#" target="_blank">
                                        Mortgage Expert
                                        <i class='bx bx-right-arrow-alt'></i>
                                    </a>
                                </li>
                                <li>
                                    <a 
                                        v-on:click="isPopupMethod(isPopup)"
                                        class="banner-play popup-youtube" 
                                        href="javascript:void(0)"
                                    >
                                        <i class='bx bx-play'></i>
                                    </a>
                                    <span>How It’s Work</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>