<template>
    <div id="team" class="one-team-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">OUR TEAM</span>
                <h2>Our Staff Profiles</h2>
                <a href="#" target="_blank">
                    <i class='bx bx-plus'></i>
                    JOIN TEAM
                </a>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-img">
                            <img src="../../assets/img/home-one/team1.png" alt="Team">
                        </div>
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.twitter.com/">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                        <h3>David Driscoll</h3>
                        <span>Manager</span>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-img">
                            <img src="../../assets/img/home-one/team2.png" alt="Team">
                        </div>
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.twitter.com/">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                        <h3>Andon wardin</h3>
                        <span>Loan Officer</span>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-img">
                            <img src="../../assets/img/home-one/team3.png" alt="Team">
                        </div>
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.twitter.com/">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                        <h3>Mark morrell</h3>
                        <span>Area Manager</span>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-img">
                            <img src="../../assets/img/home-one/team4.png" alt="Team">
                        </div>
                        <ul>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.twitter.com/">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                        <h3>JUBIN INDONA</h3>
                        <span>Accountant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurTeam'
}
</script>