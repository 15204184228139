<template>
    <div class="two-address-area pt-100">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">GET ADDRESS</span>
                <h2>Here Is Our Roadmap</h2>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="address-item">
                        <img src="../../assets/img/home-two/address1.jpg" alt="Address">
                        <ul>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxs-home'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bx-street-view'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxs-graduation'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxs-folder-open'></i>
                                </a>
                            </li>
                        </ul>
                        <h3>You’re In Right Place!</h3>
                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="address-item">
                        <img src="../../assets/img/home-two/address2.jpg" alt="Address">
                        <ul>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxs-home'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bx-street-view'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxs-graduation'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxs-folder-open'></i>
                                </a>
                            </li>
                        </ul>
                        <h3>You Can See The Map</h3>
                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="one-quote-area">
            <div class="container">
                <div class="row m-0">
                    <div class="col-lg-6 p-0">
                        <div class="quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                            <a href="#" target="_blank">
                                <i class='bx bxs-quote-alt-right'></i>
                                Get A Quote
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 p-0">
                        <div class="quote-img">
                            <img src="../../assets/img/home-one/quote.jpg" alt="Quote">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetAddress'
}
</script>