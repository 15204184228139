<template>
    <div class="one-search-area ptb-100">
        <div class="container">
            <div class="search-wrap">
                <div class="search-content">
                    <div class="one-section-title">
                        <span class="sub-title">{{subTitle}}</span>
                        <h2>{{heading}}</h2>
                    </div>
                    <form>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                            <label class="form-check-label" for="exampleRadios1">
                                Real Estate Agents
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                            <label class="form-check-label" for="exampleRadios2">
                                Loan Officer
                            </label>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="First Name">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Last Name">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <select class="form-select">
                                        <option>License State</option>
                                        <option>Another option</option>
                                        <option>A option</option>
                                        <option>Potato</option>
                                    </select>	
                                </div>
                            </div>
                        </div>
                        <div class="search-link">
                            <button type="submit" class="btn">Search Your Result</button>
                        </div>
                    </form>
                </div>
                <div class="search-img">
                    <img src="../../assets/img/home-one/search-man.png" alt="Search">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AmongThem',
    props: ['subTitle', 'heading']
}
</script>