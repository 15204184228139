<template>
    <div id="about" class="three-about-area pb-70">
        <div class="about-shape">
            <img src="../../assets/img/home-three/about4.png" alt="About">
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-img">
                        <img src="../../assets/img/home-three/about1.jpg" alt="About">
                        <img src="../../assets/img/home-three/about2.png" alt="About">
                        <img src="../../assets/img/home-three/about3.png" alt="About">
                        <div class="about-inner">
                            <h3>20+ Years</h3>
                            <p>Lorem ipsum dolor sit amet, ctur re et dolore magnam aliquam quaerat luptatem.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-content">
                        <div class="one-section-title two-section-title">
                            <span class="sub-title">ABOUT US</span>
                            <h2>We Are Fully Dedicated To Support You</h2>
                        </div>
                        <div class="tabs">
                            <ul class="tabs__header">
                                <li 
                                    class="tabs__header-item" 
                                    v-for="tab in tabs" 
                                    v-on:click="selectTab (tab.id)" 
                                    v-bind:class="{ 'active': activeTab == tab.id }"
                                    :key="tab.id"
                                >
                                    <i :class="tab.icon"></i>
                                    {{tab.name}}
                                </li>
                            </ul>
                        </div>
                        <div class="tabs__container">
                            <ul 
                                class="tabs__list"
                                ref='tabsList'
                            >
                                <li 
                                    class="tabs__list-tab"
                                    v-for="tab in tabs"
                                    v-bind:class="{ 'active': activeTab == tab.id }"
                                    :key="tab.id"
                                >
                                    <p>{{tab.content}}</p>
                                    <ul>
                                        <li>
                                            <i class='bx bx-chevrons-right'></i>
                                            Respect for all people
                                        </li>
                                        <li>
                                            <i class='bx bx-chevrons-right'></i>
                                            Excellence in everything we do
                                        </li>
                                        <li>
                                            <i class='bx bx-chevrons-right'></i>
                                            Truthfulness in our business
                                        </li>
                                    </ul>
                                    <a class="cmn-banner-btn" href="#" target="_blank">
                                        Learn More
                                        <i class='bx bx-right-arrow-alt'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',
    data (){
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-bullseye',
                    name: 'Our Mission',
                    content: 'Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit'
                },
                {
                    id: 2,
                    icon: 'bx bx-show-alt',
                    name: 'OUR VISION',
                    content: 'Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit'
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
        let tabsList = this.$refs.tabsList
        this.activeTab = id
        this.offsetRight = tabsList.clientWidth * (id - 1)
        tabsList.style.right = this.offsetRight + 'px'
        }
    }, 
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        }
    }
}
</script>