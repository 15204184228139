<template>
    <div class="mortgage-quote-area-with-full-width">
        <div class="container-fluid">
            <div class="row m-0">
                <div class="col-lg-6 col-md-6 p-0">
                    <div class="mortgage-quote-content">
                        <h3>Get Mortgage Quote</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                        <a href="#" class="quote-btn">Get Mortgage Quote</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 p-0">
                    <div class="mortgage-quote-image"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MortgageQuote'
}
</script>