<template>
    <div class="three-loan-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title two-section-title">
                <span class="sub-title">CATEGORIES OF LOAN</span>
                <h2>Find A Own Loan</h2>
            </div>
            <div class="sorting-menu">
                <ul> 
                    <li 
                        class="filter"
                        :class="{active:itemFilterKey == 'all'}" 
                        v-on:click="itemFilterKey = 'all'" 
                    >
                        <i class='bx bx-cart'></i>
                        PURCHASE
                    </li>
                    <li 
                        class="filter"
                        :class="{active:itemFilterKey == 'homebuyer'}" 
                        v-on:click="itemFilterKey = 'homebuyer'" 
                    >
                        <i class='bx bx-user'></i>
                        HOMEBUYER
                    </li>
                    <li 
                        class="filter" 
                        :class="{active:itemFilterKey == 'refinance'}" 
                        v-on:click="itemFilterKey = 'refinance'" 
                    >
                        <i class='bx bx-dollar' ></i>
                        REFINANCE
                    </li>
                </ul>
            </div>
            <div id="Container" class="row">
                <div 
                    v-for="item in itemFilter"
                    :key="item.id"
                    class="col-sm-6 col-lg-3"
                >
                    <div class="loan-item">
                        <span>{{item.subTitle}}</span>
                        <h3>{{item.title}}</h3>
                        <p>{{item.desc}}</p>
                        <a href="#" target="_blank">
                            {{item.btnText}}
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoriesOfLoan',
    data: function (){
        return {
            itemFilterKey: 'all',
            items: [
                {
                    id: 1,
                    subTitle: 'Home Loan',
                    title: 'A Conventional Home Loan',
                    desc: 'Migration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'homebuyer'
                },
                {
                    id: 2,
                    subTitle: 'Homeready',
                    title: 'Homeready Fannie Mortgage',
                    desc: 'Fyigration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'refinance'
                },
                {
                    id: 3,
                    subTitle: 'High Balance',
                    title: 'High Balance Mortgage',
                    desc: 'Wigration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'refinance'
                },
                {
                    id: 4,
                    subTitle: 'Renovation Loans',
                    title: 'Renovation Loans For Buyers',
                    desc: 'Migration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'homebuyer',
                },
                {
                    id: 5,
                    subTitle: 'VA Loans',
                    title: 'VA Loans For The Military',
                    desc: 'Migration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'refinance'
                },
                {
                    id: 6,
                    subTitle: 'Jumbo Loans',
                    title: 'When You Need Conforming Limit',
                    desc: 'Fyigration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'homebuyer'
                },
                {
                    id: 7,
                    subTitle: 'Purchase Loans',
                    title: 'Down Payment Has Stalled',
                    desc: 'Wigration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'refinance'
                },
                {
                    id: 8,
                    subTitle: 'Condo Loans',
                    title: 'Loans For Condo Support',
                    desc: 'Migration do amet contur diisci velit suia nonnua',
                    btnText: 'READ MORE',
                    key: 'homebuyer'
                },
            ]
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        homebuyer(){
            return this.items.filter((item) => item.key == 'homebuyer')
        },
        refinance(){
            return this.items.filter((item) => item.key == 'refinance')
        },
    }
}
</script>