<template>
    <div class="three-process-area pb-70">
        <div class="counter-shape">
            <img src="../../assets/img/home-three/process6.png" alt="Process">
            <img src="../../assets/img/home-three/process7.png" alt="Process">
        </div>
        <div class="container-fluid">
            <div class="one-section-title two-section-title">
                <span class="sub-title">OUR STEP AND PROCESS</span>
                <h2>Go Beyond With Cavo</h2>
            </div>
            <div class="row align-items-center">
                <div class="col-sm-6 col-lg-4">
                    <div class="process-item">
                        <div class="process-span">
                            <span>04</span>
                        </div>
                        <h3>Appraisal</h3>
                        <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                    </div>
                    <div class="process-item">
                        <div class="process-span">
                            <span>05</span>
                        </div>
                        <h3>Upfront Underwriting</h3>
                        <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                    </div>
                    <div class="process-item">
                        <div class="process-span">
                            <span>06</span>
                        </div>
                        <h3>Congratulations!</h3>
                        <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="process-img">
                        <img src="../../assets/img/home-three/process1.jpg" alt="Process">
                        <img src="../../assets/img/home-three/process2.png" alt="Process">
                        <img src="../../assets/img/home-three/process3.png" alt="Process">
                        <img src="../../assets/img/home-three/process4.png" alt="Process">
                        <img src="../../assets/img/home-three/process4.png" alt="Process">
                        <img src="../../assets/img/home-three/process4.png" alt="Process">
                        <img src="../../assets/img/home-three/process4.png" alt="Process">
                    </div>
                </div>
                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="process-item process-two">
                        <div class="process-span">
                            <span>01</span>
                        </div>
                        <h3>Conversation</h3>
                        <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                    </div>
                    <div class="process-item process-two">
                        <div class="process-span">
                            <span>02</span>
                        </div>
                        <h3>Application</h3>
                        <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                    </div>
                    <div class="process-item process-two">
                        <div class="process-span">
                            <span>03</span>
                        </div>
                        <h3>Documents</h3>
                        <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurStepAndProcess'
}
</script>