<template>
    <div class="get-quote-style-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="get-quote-style-content">
                        <span class="sub-title">Get A Quote</span>
                        <h3>Get Mortgage Quote If You have any Doubt It</h3>

                        <ul class="quote-btn">
                            <li>
                                <a href="#" class="main-default-btn">Join community</a>
                            </li>
                            <li>
                                <a href="#" class="main-optional-btn">Get a quote</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="get-quote-style-image">
                        <img src="../../assets/img/more-home/get-quote/quote.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="get-quote-style-shape-1">
            <img src="../../assets/img/more-home/get-quote/shape-1.png" alt="image">
        </div>
        <div class="get-quote-style-shape-2">
            <img src="../../assets/img/more-home/get-quote/shape-2.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MortgageQuote'
}
</script>