<template>
    <div>
        <TopHeaderOne class="four-header-top" />
        <NavbarTwo />
        <MainBanner />
        <Time />
        <AboutUs />
        <WhatsYouExpect />
        <Quote />
        <OurAchievement />
        <StepsProcess />
        <TeamLeadership />
        <CategoriesOfLoan class="four-loan-area" />
        <Join />
        <Faq />
        <OurClientsLove />
        <Blog class="four-blog-area" />
        <Subscribe />
        <Map />
        <FooterTwo />
    </div>
</template>

<script>
import TopHeaderOne from '../Layouts/TopHeaderOne'
import NavbarTwo from '../Layouts/NavbarTwo'
import MainBanner from '../HomeFour/MainBanner'
import Time from '../HomeFour/Time'
import AboutUs from '../HomeFour/AboutUs'
import WhatsYouExpect from '../HomeFour/WhatsYouExpect'
import Quote from '../HomeFour/Quote'
import OurAchievement from '../HomeFour/OurAchievement'
import StepsProcess from '../Common/StepsProcess'
import TeamLeadership from '../Common/TeamLeadership'
import CategoriesOfLoan from '../Common/CategoriesOfLoan'
import Join from '../HomeFour/Join'
import Faq from '../HomeFour/Faq'
import OurClientsLove from '../HomeFour/OurClientsLove'
import Blog from '../Common/Blog'
import Subscribe from '../Common/Subscribe'
import Map from '../Common/Map'
import FooterTwo from '../Layouts/FooterTwo'

export default {
    name: 'HomePageFour',
    components: {
        TopHeaderOne,
        NavbarTwo,
        MainBanner,
        Time,
        AboutUs,
        WhatsYouExpect,
        Quote,
        OurAchievement,
        StepsProcess,
        TeamLeadership,
        CategoriesOfLoan,
        Join,
        Faq,
        OurClientsLove,
        Blog,
        Subscribe,
        Map,
        FooterTwo,
    }
}
</script>