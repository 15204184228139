<template>
    <div>
        <TopHeaderThree />
        <NavbarThree />
        <MainBanner />
        <About />
        <Services />
        <BuyingHome />
        <MortgageQuote />
        <WhyChooseUs />
        <FindLoan />
        <StaffProfiles />
        <Faq />
        <Testimonials />
        <Contact />
        <Blog />
        <SubscribeTwo />
        <FooterThree />
    </div>
</template>

<script>
import TopHeaderThree from '../Layouts/TopHeaderThree'
import NavbarThree from '../Layouts/NavbarThree'
import MainBanner from '../HomeSeven/MainBanner'
import About from '../HomeSeven/About'
import Services from '../Common/Services'
import BuyingHome from '../HomeSeven/BuyingHome'
import MortgageQuote from '../Common/MortgageQuote'
import WhyChooseUs from '../HomeSeven/WhyChooseUs'
import FindLoan from '../HomeSeven/FindLoan'
import StaffProfiles from '../Common/StaffProfiles'
import Faq from '../HomeSeven/Faq'
import Testimonials from '../HomeSeven/Testimonials'
import Contact from '../HomeSeven/Contact'
import Blog from '../HomeSeven/Blog'
import SubscribeTwo from '../Common/SubscribeTwo'
import FooterThree from '../Layouts/FooterThree'

export default {
    name: 'HomePageSeven',
    components: {
        TopHeaderThree,
        NavbarThree,
        MainBanner,
        About,
        Services,
        BuyingHome,
        MortgageQuote,
        WhyChooseUs,
        FindLoan,
        StaffProfiles,
        Faq,
        Testimonials,
        Contact,
        Blog,
        SubscribeTwo,
        FooterThree,
    }
}
</script>