import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Vue3Autocounter from "vue3-autocounter";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css"; 
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import 'gitart-vue-dialog/dist/style.css';

import "./assets/custom.scss";
import i18n from './i18n'

const app = createApp(App).use(i18n).component("vue3-autocounter", Vue3Autocounter);
app.use(BootstrapVue3).use(router).mount("#app");