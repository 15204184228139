<template>
    <div class="one-among-area two-among-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title">
                <span class="sub-title">WHY CHOOSE US</span>
                <h2>We Have Offer To You</h2>
                <p>Choose started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua  </p>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among1.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bxs-badge-dollar'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Delivering A Great Customer Experience</h3>
                            <p>Gain home equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among2.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bx-bulb'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <p>Your home equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among3.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bx-money'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Have A Superior Power To Unlock The Today’s Rate</h3>
                            <p>Money rate equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among4.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bx-user'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Without A Doubt To Have A Apply For Loan Offer</h3>
                            <p>Inquri home equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among5.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bx-message-alt-detail'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                            <p>Divided home equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among6.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bx-home'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>A Ethical Solution For Future Buying & Selling Home</h3>
                            <p>Fuuture home equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WeHaveOffer'
}
</script>