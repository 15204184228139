<template>
    <div id="blog" class="blog-style-area pt-100 pb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">News & Blog</span>
                <h2>Featured News And Insights</h2>
            </div>

            <div class="blog-style-slider">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="blog-style-card">
                            <div class="tag">{{slide.tag}}</div>
                            <ul class="post-meta">
                                <li>
                                    <div class="post-author">
                                        <img :src="slide.image" class="rounded-circle" alt="image">
                                        <span>{{slide.name}}</span>
                                    </div>
                                </li>
                                <li>
                                    <i class='bx bxs-calendar'></i> 
                                    {{slide.date}}
                                </li>
                            </ul>
                            <h3>
                                <a href="#">{{slide.title}}</a>
                            </h3>
                            <p>{{slide.desc}}</p>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'Blog',
    components: {
        Carousel,
        Slide,
        Pagination,
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                tag: 'Mortgage',
                image: require('../../assets/img/more-home/user-1.jpg'),
                name: 'Esther Cooper',
                date: 'Oct 07, 2022',
                title: 'Refinancing Your Home No Doubt About Anything',
                desc: 'Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor.'
            },
            {
                id: 2,
                tag: 'Privacy',
                image: require('../../assets/img/more-home/user-2.jpg'),
                name: 'Theresa Web',
                date: 'Oct 08, 2022',
                title: 'What Means Your Mortgage And 5 Ways To Improve',
                desc: 'Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor.'
            },
            {
                id: 3,
                tag: 'Privacy',
                image: require('../../assets/img/more-home/user-3.jpg'),
                name: 'Jenny Wilson',
                date: 'Oct 09, 2022',
                title: '5 Ways To build Yourself By Hard Working',
                desc: 'Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor.'
            },
            {
                id: 4,
                tag: 'Mortgage',
                image: require('../../assets/img/more-home/user-1.jpg'),
                name: 'Esther Cooper',
                date: 'Oct 07, 2022',
                title: 'Refinancing Your Home No Doubt About Anything',
                desc: 'Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor.'
            },
            {
                id: 5,
                tag: 'Privacy',
                image: require('../../assets/img/more-home/user-2.jpg'),
                name: 'Theresa Web',
                date: 'Oct 08, 2022',
                title: 'What Means Your Mortgage And 5 Ways To Improve',
                desc: 'Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor.'
            },
            {
                id: 6,
                tag: 'Privacy',
                image: require('../../assets/img/more-home/user-3.jpg'),
                name: 'Jenny Wilson',
                date: 'Oct 09, 2022',
                title: '5 Ways To build Yourself By Hard Working',
                desc: 'Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor.'
            },
        ],
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
			1200: {
                itemsToShow: 3,
                snapAlign: 'center',
			}
        },
    }),
})
</script>