<template>
    <div class="four-record-area pb-70">
        <div class="record-shape">
            <img src="../../assets/img/home-four/record2.png" alt="Shape">
            <img src="../../assets/img/home-four/record3.png" alt="Shape">
        </div>
        <div class="container-fluid p-0">
            <div class="row m-0 align-items-center">
                <div class="col-lg-6 p-0">
                    <div class="record-img">
                        <img src="../../assets/img/home-four/record.jpg" alt="Record">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="record-content">
                        <div class="one-section-title three-section-title">
                            <span class="sub-title"># OUR RECORD</span>
                            <h2>Our Achievement Of Hard Working</h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-lg-6">
                                <div class="record-item">
                                    <h3>20</h3>
                                    <span>YEARS OF EXPERIENCE</span>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="record-item">
                                    <h3>98</h3>
                                    <span>VOLUNTEERS PARTICIPATED</span>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="record-item">
                                    <h3>56</h3>
                                    <span>VETERAN HOMES COMPLETED</span>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="record-item">
                                    <h3>90</h3>
                                    <span>CUSTOMER WORLDWIDE</span>
                                </div>
                            </div>
                        </div>
                        <a class="cmn-banner-btn" href="#" target="_blank">
                            Join Community
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurAchievement'
}
</script>