<template>
    <div id="service" class="four-expect-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title three-section-title">
                <span class="sub-title"># WHAT'S YOU EXPECT</span>
                <h2>We Have Offer For You That You Expect</h2>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <div class="expect-item">
                        <div class="expect-icon">
                            <i class='bx bx-money'></i>
                        </div>
                        <h3>Get Mortgage Rate</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit ed do eiusmod tempor incididunt laboret dolore magUt enim ad minim </p>
                        <a href="#" target="_blank">
                            Read More
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                    <div class="expect-item active">
                        <div class="expect-icon">
                            <i class='bx bxs-hand-up'></i>
                        </div>
                        <h3>Home Buyer’s Guide</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit ed do eiusmod tempor incididunt laboret dolore magUt enim ad minim </p>
                        <a href="#" target="_blank">
                            Read More
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                    <div class="expect-item">
                        <div class="expect-icon">
                            <i class='bx bx-home-smile'></i>
                        </div>
                        <h3>Home Equity</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit ed do eiusmod tempor incididunt laboret dolore magUt enim ad minim </p>
                        <a href="#" target="_blank">
                            Read More
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                    <div class="expect-item">
                        <div class="expect-icon">
                            <i class='bx bx-dollar-circle'></i>
                        </div>
                        <h3>Refinancing Service</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit ed do eiusmod tempor incididunt laboret dolore magUt enim ad minim </p>
                        <a href="#" target="_blank">
                            Read More
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatsYouExpect'
}
</script>