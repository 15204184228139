<template>
    <div class="three-review-area four-review-area pb-100">
        <div class="container">
            <div class="one-section-title two-section-title">
                <span class="sub-title"># CLIENT'S LOVE</span>
                <h2>Our Clients Love</h2>
            </div>
            <div class="three-review-slider">
                <carousel 
                    :autoplay="4000"
                    :wrap-around="true"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="review-item">
                            <i class='bx bxs-quote-left'></i>
                            <h2>{{slide.desc}}</h2>
                            <div class="review-bottom">
                                <img :src="slide.image" alt="Review">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </slide>
                    
                    <template #addons>
                        <Pagination  />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Pagination , Slide, } from 'vue3-carousel';

export default {
    name: 'OurClientsLove',
    components: {
        Carousel,
        Slide,
        Pagination 
    },
    data() {
        return {
            carouselItems: [
                {
                    id: 1,
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod te iidunt ut labore et dolore odit aut fugit sed quia dolores eos qui ratione vluptatem sequi nidunt ut labore et dolore magnam aliquam',
                    image: require('../../assets/img/home-three/review.png'),
                    name: 'JAMES TAYLOR',
                    position: 'Policy Advisor',
                },
                {
                    id: 2,
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod te iidunt ut labore et dolore odit aut fugit sed quia dolores eos qui ratione vluptatem sequi nidunt ut labore et dolore magnam aliquam',
                    image: require('../../assets/img/home-three/review.png'),
                    name: 'JAMES TAYLOR',
                    position: 'Policy Advisor',
                },
                {
                    id: 3,
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod te iidunt ut labore et dolore odit aut fugit sed quia dolores eos qui ratione vluptatem sequi nidunt ut labore et dolore magnam aliquam',
                    image: require('../../assets/img/home-three/review.png'),
                    name: 'JAMES TAYLOR',
                    position: 'Policy Advisor',
                },
            ]
        }
    },
}
</script>