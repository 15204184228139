<template>
    <div class="why-choose-us-area-without-bg pt-100 pb-70">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Choose Your Service</span>
                <h2>Nearly We Have Been Trying To Provide Those</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="why-choose-us-item">
                        <div class="choose-image">
                            <img src="../../assets/img/more-home/choose/choose-4.png" alt="image">
                        </div>
                        <div class="choose-content">
                            <h3>Floor Plan Financing</h3>
                            <ul class="list">
                                <li><i class='bx bx-check-circle'></i> Dealership to the next level</li>
                                <li><i class='bx bx-check-circle'></i> Financing option for your dealership</li>
                                <li><i class='bx bx-check-circle'></i> Business insight center</li>
                            </ul>
                            <a href="#" class="choose-btn">Get Quote</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="why-choose-us-item">
                        <div class="choose-image">
                            <img src="../../assets/img/more-home/choose/choose-5.png" alt="image">
                        </div>
                        <div class="choose-content">
                            <h3>Conventional Loans</h3>
                            <ul class="list">
                                <li><i class='bx bx-check-circle'></i> Require a minimum down payment</li>
                                <li><i class='bx bx-check-circle'></i> Appeal to borrowers with a strong credit</li>
                                <li><i class='bx bx-check-circle'></i> Conventional Loans have flexibility.</li>
                            </ul>
                            <a href="#" class="choose-btn">Get Quote</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="why-choose-us-item">
                        <div class="choose-image">
                            <img src="../../assets/img/more-home/choose/choose-6.png" alt="image">
                        </div>
                        <div class="choose-content">
                            <h3>MMS Mortgage Services</h3>
                            <ul class="list">
                                <li><i class='bx bx-check-circle'></i> Approval process smooth and easy</li>
                                <li><i class='bx bx-check-circle'></i> You can stay on schedule</li>
                                <li><i class='bx bx-check-circle'></i> We can help you overcome obstacles</li>
                            </ul>
                            <a href="#" class="choose-btn">Get Quote</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseYourService'
}
</script>