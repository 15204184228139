<template>
    <footer class="footer-style-area pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-6 col-lg-3">
                    <div class="single-footer-widget">
                        <div class="footer-logo">
                            <a href="#">
                                <img src="../../assets/img/logo-two.png" alt="Logo">
                            </a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <ul class="footer-social">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.twitter.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-2">
                    <div class="single-footer-widget ps-3">
                        <h3>Quick Links</h3>
                        
                        <ul class="quick-link">
                            <li>
                                <a href="#" target="_blank">About Us</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">What We Offer</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">Why Choose Us</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-2">
                    <div class="single-footer-widget ps-3">
                        <h3>Company</h3>
                        
                        <ul class="quick-link">
                            <li>
                                <a href="#" target="_blank">Blog</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">FAQ</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">Loan Calculator</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">Mortgage Calculator</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-2">
                    <div class="single-footer-widget ps-3">
                        <h3>Support</h3>
                        
                        <ul class="quick-link">
                            <li>
                                <a href="#" target="_blank">Contact</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">Loan Calculator</a>
                            </li>
                            <li>
                                <a href="#" target="_blank">Terms & Conditionsr</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="single-footer-widget">
                        <h3>Address</h3>

                        <ul class="address-info">
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+4498886660000">+449 888 666 0000</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:hello@cavo.com">hello@cavo.com</a>
                            </li>
                            <li>
                                <i class='bx bx-current-location' ></i>
                                855 road, broklyn street new york 600
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="copyright-style-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="copyright-item">
                            <p>Copyright @2022 Cavo. Designed By <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="copyright-item">
                            <ul class="payment-list">
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/visa.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/master-card.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/paypal.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/skrill.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/master-card2.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/visa2.jpg" alt="Method">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterThree'
}
</script>