<template>
    <div class="one-quote-area four-quote-area">
        <div class="container">
            <div class="row m-0">
                <div class="col-lg-12 p-0">
                    <div class="quote-content">
                        <h3>Get Mortgage Quote</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                        <a href="#" target="_blank">
                            <i class='bx bxs-quote-alt-right'></i>
                            Get A Quote
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Quote'
}
</script>