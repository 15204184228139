<template>
    <div>
        <div id="home" class="three-banner-area">
            <div class="banner-shape">
                <img src="../../assets/img/home-three/banner-shape2.png" alt="Banner">
                <img src="../../assets/img/home-three/banner-shape3.png" alt="Banner">
            </div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-7">
                                <div class="banner-content">
                                    <span>
                                        <img src="../../assets/img/home-three/banner-icon.png" alt="Banner">
                                        Welcome To Cavo
                                    </span>
                                    <h1>Buy And Get Better Home</h1>
                                    <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tr inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>
                                    <ul>
                                        <li>
                                            <a class="cmn-banner-btn" href="#" target="_blank">
                                                Mortgage Expert
                                                <i class='bx bx-right-arrow-alt'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a 
                                                href="javascript:void(0)" 
                                                class="banner-play popup-youtube"
                                                v-on:click="isPopupMethod(isPopup)"
                                            >
                                                <i class='bx bx-play' ></i>
                                            </a>
                                            <span>How It’s Work</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="banner-img">
                                    <img src="../../assets/img/home-three/banner1.png" alt="Banner">
                                    <img src="../../assets/img/home-three/banner-shape1.png" alt="Banner">
                                    <img src="../../assets/img/home-three/banner-shape1.png" alt="Banner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>