<template>
    <div id="service" class="one-among-area two-among-area three-among-area pt-100">
        <div class="container">
            <div class="one-section-title two-section-title">
                <span class="sub-title">WHAT'S YOU EXPECT</span>
                <h2>We Have Offer For You</h2>
                <p>Choose started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua  </p>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among1.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bxs-badge-dollar'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Delivering A Great Customer</h3>
                            <p>Gain home equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among2.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bx-bulb'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Refinancing Your Home</h3>
                            <p>Your home equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="among-item">
                        <div class="among-top">
                            <img src="../../assets/img/home-two/among3.png" alt="Among">
                            <div class="among-icon">
                                <i class='bx bx-money'></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>Unlock The Today’s Rate</h3>
                            <p>Money rate equity by paying sit amet seeturlit sed dolor se equity </p>
                            <a href="#" target="_blank">
                                READ MORE
                                <i class="bx bx-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="one-quote-area">
            <div class="container">
                <div class="row m-0">
                    <div class="col-lg-6 p-0">
                        <div class="quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                            <a href="#" target="_blank">
                                <i class='bx bxs-quote-alt-right'></i>
                                Get A Quote
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 p-0">
                        <div class="quote-img">
                            <img src="../../assets/img/home-one/quote.jpg" alt="Quote">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatsYouExpect'
}
</script>