<template>
    <div class="subscribe-style-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="subscribe-style-wrap-content">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <h2>Do You Want to Get Update What’s Upcoming?</h2>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required>
        
                                <button class="main-default-btn" type="submit">
                                    Subscribe Now
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscribeTwo'
}
</script>