<template>
    <div class="fun-fact-style-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="fun-fact-style-card">
                                <h3>20</h3>
                                <p>Years Of Experience</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="fun-fact-style-card bg-E5F9F4">
                                <h3>90</h3>
                                <p>Customer Worldwide</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="fun-fact-style-card bg-E5F9F4">
                                <h3>56</h3>
                                <p>Volunteers Participated</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="fun-fact-style-card">
                                <h3>98</h3>
                                <p>Veteran Home Completed</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="fun-fact-style-content">
                        <span class="sub-title">Our Record</span>
                        <h3>Our Achievement Of Hard Working</h3>
                        <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                        <div class="fun-fact-btn">
                            <a href="#" class="main-default-btn">Join Community</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfact'
}
</script>