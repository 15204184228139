<template>
    <div>
        <div class="find-loan-officer-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="find-loan-officer-form">
                            <h3>Find A Loan Officer</h3>
                            
                            <div class="loan-check-box">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                                    <label class="form-check-label" for="exampleRadios1">
                                        Real Estate Agents
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                    <label class="form-check-label" for="exampleRadios2">
                                        Loan Officer
                                    </label>
                                </div>
                            </div>

                            <form>
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" class="form-control" placeholder="Darlene">
                                </div>
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" placeholder="Robertson">
                                </div>
                                <div class="form-group">
                                    <label>License State</label>
                                
                                    <div class="select-box">
                                        <select>
                                            <option>License State</option>
                                            <option>Canada</option>
                                            <option>Japan</option>
                                            <option>Germany</option>
                                            <option>Switzerland</option>
                                            <option>Australia</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="loan-button">
                                    <button type="submit" class="default-btn">Search Your Result</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="find-loan-officer-image">
                            <img src="../../assets/img/more-home/loan-officer.jpg" alt="image">

                            <div class="find-loan-popup">
                                <a 
                                    href="javascript:void(0)" 
                                    class="video-btn"
                                    v-on:click="isPopupMethod(isPopup)"
                                >
                                    <i class='bx bx-play-circle' ></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FindLoan',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>