<template>
    <div id="contact" class="information-area with-bg-main-color pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="information-card">
                        <div class="icon">
                            <i class='bx bx-time'></i>
                        </div>
                        <p>
                            <span>Mon-Fri (8am - 6pm)</span>
                            <span>Sat-Sun (10am - 2pm)</span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="information-card">
                        <div class="icon">
                            <i class='bx bxs-contact'></i>
                        </div>
                        <p>
                            <span>Contact us</span>
                            <span><a href="mailto:support@cavo.com">support@cavo.com</a></span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="information-card">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <p>
                            <span>(24 hours / 7 days)</span>
                            <span><a href="tel:4234543123678">+4 234-543 123 678</a></span>
                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="information-card">
                        <div class="icon">
                            <i class='bx bx-world'></i>
                        </div>
                        <p>
                            <span>9675 Grant Ave.</span>
                            <span>Chicago Heights, IL 60411</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="let-contact-form-with-gray-color">
                <div class="main-section-title">
                    <span class="sub-title">Let’s Contact</span>
                    <h2>Contact With Us</h2>
                </div>

                <form>
                    <div class="row m-0">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" id="name" class="form-control" required placeholder="William">
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="email" id="email" class="form-control" required placeholder="cavo@gmail.com">
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>Phone Number</label>
                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="+4 234-543 123 678">
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label>Subject</label>
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="your subject">
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12">
                            <div class="form-group">
                                <label>Your Message</label>
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="write your message"></textarea>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12">
                            <div class="let-contact-btn">
                                <button type="submit" class="main-default-btn">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
                
                <div class="let-contact-shape">
                    <img src="../../assets/img/more-home/let-contact-shape.png" alt="image">
                </div>
            </div>
        </div>

        <div class="information-shape-1">
            <img src="../../assets/img/more-home/information-shape-1.png" alt="image">
        </div>
        <div class="information-shape-2">
            <img src="../../assets/img/more-home/information-shape-2.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Information'
}
</script>