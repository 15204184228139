<template>
    <div class="two-lover-area five-lover-area pt-100 pb-70">
        <div class="container">
            <div class="one-section-title three-section-title">
                <span class="sub-title"># TESTIMONIAL</span>
                <h2>We Serve Our Clients Best Of Capacity</h2>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="lover-item">
                        <ul>
                            <li>
                                <div class="lover-content">
                                    <h3>Donney Jon</h3>
                                    <span>Perisian Org</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                    </ul>
                                    <div class="lover-inner-img">
                                        <img src="../../assets/img/home-two/lover1.png" alt="Lover">
                                    </div>
                                    <div class="lover-icon">
                                        <i class='bx bxs-quote-left'></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="lover-item">
                        <ul>
                            <li>
                                <div class="lover-content">
                                    <h3>Oli Rubion</h3>
                                    <span>Entoin Ect</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star checked'></i>
                                        </li>
                                        <li>
                                            <i class='bx bxs-star'></i>
                                        </li>
                                    </ul>
                                    <div class="lover-inner-img">
                                        <img src="../../assets/img/home-two/lover2.png" alt="Lover">
                                    </div>
                                    <div class="lover-icon">
                                        <i class='bx bxs-quote-left'></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Testimonial'
}
</script>