<template>
    <div id="team" class="staff-area ptb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Our Team</span>
                <h2>Our Staff Profiles</h2>
            </div>

            <div class="staff-slider">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="staff-card">
                            <div class="staff-image">
                                <img :src="slide.image" alt="image">

                                <ul class="social-link">
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/">
                                            <i class='bx bxl-facebook'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/">
                                            <i class='bx bxl-twitter'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/">
                                            <i class='bx bxl-instagram'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/">
                                            <i class='bx bxl-linkedin'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="staff-content">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'StaffProfiles',
    components: {
        Carousel,
        Slide,
        Pagination,
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/img/more-home/staff/PingChen.jpg'),
                name: 'Ping Chen',
                position: 'Manager'
            },
            {
                id: 2,
                image: require('../../assets/img/more-home/staff/AnnetteWoodroffe.jpg'),
                name: 'Annette',
                position: 'Loan Officer'
            },
            {
                id: 3,
                image: require('../../assets/img/more-home/staff/Donnelle.jpg'),
                name: 'Donnelle Corsby',
                position: 'Area Manager'
            },
            {
                id: 4,
                image: require('../../assets/img/more-home/staff/Jeff.jpg'),
                name: 'Jeff Ussher',
                position: 'Accountant'
            }
        ],
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 4,
                snapAlign: 'center',
            }
        },
    }),
})
</script>