<template>
    <div class="two-house-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="house-item">
                        <div class="house-icon">
                            <i class='bx bx-dollar-circle'></i>
                        </div>
                        <h3>Mortgage Pre-Approval</h3>
                        <p>You can grow in your new homeit amet seeturlit sed ew homeew homedolor</p>
                        <a href="#" target="_blank">
                            READ MORE
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="house-item">
                        <div class="house-icon">
                            <i class='bx bx-shopping-bag'></i>
                        </div>
                        <h3>House Shopping</h3>
                        <p>You can grow in your new homeit amet seeturlit sed ew homeew homedolor</p>
                        <a href="#" target="_blank">
                            READ MORE
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="house-item">
                        <div class="house-icon">
                            <i class='bx bx-note'></i>
                        </div>
                        <h3>Loan Application</h3>
                        <p>You can grow in your new homeit amet seeturlit sed ew homeew homedolor </p>
                        <a href="#" target="_blank">
                            READ MORE
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>