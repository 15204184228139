<template>
    <div class="three-join-area four-join-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="join-item">
                        <h3>Take The First Step Toward The Right Mortgage</h3>
                        <a class="cmn-banner-btn" href="#" target="_blank">
                            Career Opportunity
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                        <a class="cmn-banner-btn" href="#" target="_blank">
                            Join Community
                            <i class='bx bx-right-arrow-alt'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Join'
}
</script>