<template>
    <div>
        <div id="home" class="better-home-area">
            <div class="container-fluid">
                <ul class="social-links">
                    <li>
                        <a target="_blank" href="https://www.facebook.com/">
                            <i class='bx bxl-facebook'></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.twitter.com/">
                            <i class='bx bxl-twitter'></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.instagram.com/">
                            <i class='bx bxl-instagram'></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.linkedin.com/">
                            <i class='bx bxl-linkedin'></i>
                        </a>
                    </li>
                </ul>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="better-home-content">
                            <span class="sub-title">Welcome to Cavo</span>
                            <h1>Buy And Get Better Home</h1>
                            <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tr inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>
        
                            <ul class="better-home-btn">
                                <li>
                                    <a href="#" class="main-default-btn">Mortgage Expert</a>
                                </li>
                                <li>
                                    <a href="#" class="main-optional-btn">About Us</a>
                                </li>
                            </ul>
                        </div> 
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="better-home-image">
                            <img src="../../assets/img/more-home/banner/better-home.png" alt="image">

                            <a 
                                href="javascript:void(0)" 
                                class="video-btn"
                                v-on:click="isPopupMethod(isPopup)"
                            >
                                <i class='bx bx-play' ></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>