<template>
    <div id="service" class="four-step-area five-step-area six-step-area ptb-100">
        <div class="container-fluid">
            <div class="one-section-title three-section-title">
                <span class="sub-title"># STEPS & PROCESS</span>
                <h2>Our Steps And Process How Works</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed diusmod temlaboret dolore magna aliqua. Ut enim ad minim veniamquaera</p>
            </div>
            <div class="four-step-slider">
                <carousel 
                    :autoplay="5000"
                    :wrap-around="true"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div :class="slide.class">
                            <div class="step-span">
                                <span>{{slide.number}}</span>
                            </div>
                            <h3>{{slide.title}}</h3>
                            <p>{{slide.description}}</p>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'StepsProcess',
    components: {
        Carousel,
        Slide,
        Pagination
    },

    data: () => ({
        carouselItems: [
            {
                id: 1,
                class: 'step-item step-one',
                number: '01',
                title: 'Conversation',
                description: 'Learning do amet contur diiscivt suia non nuameius velit modi'
            },
            {
                id: 2,
                class: 'step-item',
                number: '02',
                title: 'Application',
                description: 'Learning do amet contur diiscivt suia non nuameius velit modi'
            },
            {
                id: 3,
                class: 'step-item step-one',
                number: '03',
                title: 'Documents',
                description: 'Learning do amet contur diiscivt suia non nuameius velit modi'
            },
            {
                id: 4,
                class: 'step-item',
                number: '04',
                title: 'Appraisal',
                description: 'Learning do amet contur diiscivt suia non nuameius velit modi'
            },
            {
                id: 5,
                class: 'step-item step-one',
                number: '05',
                title: 'Upfront Underwriting',
                description: 'Learning do amet contur diiscivt suia non nuameius velit modi'
            },
            {
                id: 6,
                class: 'step-item',
                number: '06',
                title: 'Appraisal',
                description: 'Learning do amet contur diiscivt suia non nuameius velit modi'
            },
        ],
        // carousel settings
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            600: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1000: {
                itemsToShow: 3.9,
                snapAlign: 'center',
            },
        },
    }),
})
</script>