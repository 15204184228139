<template>
    <div class="offer-style-area pt-100 pb-70">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Why Choose Us</span>
                <h2>We Have Offer To You</h2>
            </div>

            <div class="offer-style-slider">
                <carousel 
                    :autoplay="5000"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="offer-style-card with-black-color">
                            <div class="offer-image">
                                <img :src="slide.image" alt="image">
                            </div>
                            <div class="offer-content">
                                <h3>{{slide.title}}</h3>
                                <a href="#">Read More</a>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'WhyChooseUs',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/img/more-home/offer/offer-1.jpg'),
                title: 'Delivering A Great Customer Experience',
            },
            {
                id: 2,
                image: require('../../assets/img/more-home/offer/offer-2.jpg'),
                title: 'Refinancing Your Home No Doubt About Anything',
            },
            {
                id: 3,
                image: require('../../assets/img/more-home/offer/offer-3.jpg'),
                title: 'Consideration Have Been Divided Get Refinance Advisor',
            },
            {
                id: 4,
                image: require('../../assets/img/more-home/offer/offer-1.jpg'),
                title: 'Delivering A Great Customer Experience',
            },
            {
                id: 5,
                image: require('../../assets/img/more-home/offer/offer-2.jpg'),
                title: 'Refinancing Your Home No Doubt About Anything',
            },
        ],
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
			1200: {
                itemsToShow: 3,
                snapAlign: 'center',
			}
        },
    }),
})
</script>