<template>
    <div class="find-own-loan-area pt-100 pb-70">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Categories Of Loan</span>
                <h2>Find A Own Loan</h2>
            </div>

            <div class="find-own-loan-list-tab">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="find-own-loan-1-tab" data-bs-toggle="tab" href="#find-own-loan-1" role="tab" aria-controls="find-own-loan-1">Purchase</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="find-own-loan-2-tab" data-bs-toggle="tab" href="#find-own-loan-2" role="tab" aria-controls="find-own-loan-2">Homebuyer</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="find-own-loan-3-tab" data-bs-toggle="tab" href="#find-own-loan-3" role="tab" aria-controls="find-own-loan-3">Refinance</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="find-own-loan-1" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-5.jpg" alt="image">

                                        <span class="tag">Home Loan</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>A Conventional Home Loan</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-6.jpg" alt="image">

                                        <span class="tag">Homeready</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Homeready Fannie Mortgage</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-7.jpg" alt="image">

                                        <span class="tag">Renovation Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Renovation Loans For Buyers</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-8.jpg" alt="image">

                                        <span class="tag">Purchase Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Down Payment Has Stalled</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-9.jpg" alt="image">

                                        <span class="tag">Jumbo Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>When You Need Conforming Limit</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-10.jpg" alt="image">

                                        <span class="tag">High Balance</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>High Balance Mortgage</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-11.jpg" alt="image">

                                        <span class="tag">VA Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>VA Loans For Military</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-12.jpg" alt="image">

                                        <span class="tag">Condo Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Loans For Condo Support</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="find-own-loan-2" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-5.jpg" alt="image">

                                        <span class="tag">Home Loan</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>A Conventional Home Loan</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-6.jpg" alt="image">

                                        <span class="tag">Homeready</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Homeready Fannie Mortgage</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-7.jpg" alt="image">

                                        <span class="tag">Renovation Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Renovation Loans For Buyers</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-8.jpg" alt="image">

                                        <span class="tag">Purchase Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Down Payment Has Stalled</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-9.jpg" alt="image">

                                        <span class="tag">Jumbo Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>When You Need Conforming Limit</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-10.jpg" alt="image">

                                        <span class="tag">High Balance</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>High Balance Mortgage</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-11.jpg" alt="image">

                                        <span class="tag">VA Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>VA Loans For Military</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-12.jpg" alt="image">

                                        <span class="tag">Condo Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Loans For Condo Support</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="find-own-loan-3" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-5.jpg" alt="image">

                                        <span class="tag">Home Loan</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>A Conventional Home Loan</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-6.jpg" alt="image">

                                        <span class="tag">Homeready</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Homeready Fannie Mortgage</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-7.jpg" alt="image">

                                        <span class="tag">Renovation Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Renovation Loans For Buyers</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-8.jpg" alt="image">

                                        <span class="tag">Purchase Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Down Payment Has Stalled</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-9.jpg" alt="image">

                                        <span class="tag">Jumbo Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>When You Need Conforming Limit</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-10.jpg" alt="image">

                                        <span class="tag">High Balance</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>High Balance Mortgage</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-11.jpg" alt="image">

                                        <span class="tag">VA Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>VA Loans For Military</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="find-own-loan-box">
                                    <div class="loan-image">
                                        <img src="../../assets/img/more-home/find-loan/find-loan-12.jpg" alt="image">

                                        <span class="tag">Condo Loans</span>
                                    </div>
                                    <div class="loan-content">
                                        <h3>Loans For Condo Support</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FindOwnLoan'
}
</script>