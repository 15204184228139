<template>
    <div class="fun-fact-style-area-with-main-color pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="fun-fact-style-item">
                        <h3>20</h3>
                        <p>Years Of Experience</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="fun-fact-style-item">
                        <h3>90</h3>
                        <p>Customer Worldwide</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="fun-fact-style-item">
                        <h3>98</h3>
                        <p>Veteran Home Completed</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="fun-fact-style-item">
                        <h3>56</h3>
                        <p>Volunteers Participated</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="fun-fact-style-shape-2">
            <img src="../../assets/img/more-home/fun-fact-shape-2.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfact'
}
</script>