<template>
    <div id="about" class="one-about-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-6">
                    <div class="about-img">
                        <img src="../../assets/img/home-one/about.jpg" alt="About">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-content">
                        <div class="one-section-title">
                            <span class="sub-title">{{$t('about-us')}}</span>
                            <h2>{{$t('about-us-why-us')}}</h2>
                            <p><span v-html="$t('about-us-content')"></span></p>     
                        </div>
                        <!-- <div class="about-tablist">
                            <div class="tabs">
                                <ul class="tabs__header">
                                    <li 
                                        class="tabs__header-item" 
                                        v-for="tab in tabs" 
                                        v-on:click="selectTab (tab.id)" 
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        <i :class="tab.icon"></i>
                                        {{tab.name}}
                                    </li>
                                </ul>
                            </div>
                            <div class="tabs__container">
                                <ul 
                                    class="tabs__list"
                                    ref='tabsList'
                                >
                                    <li 
                                        class="tabs__list-tab"
                                        v-for="tab in tabs"
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                       <p>{{tab.content}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany',
/*     data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-bullseye',
                    name: '我们的目标',
                    content: '用您熟悉的语言为您提供及时,专业,高效的法律服务！  '
                },
                {
                    id: 2,
                    icon: 'bx bx-show-alt',
                    name: '我们的价值观',
                    content: '独立思考，紧抓重点，谨慎思辨，诚信保密的办案原则，不畏强手，不惧困难，以坚持捍卫当事人法律权利为办案之本'
                }
            ]
        }
    }, 
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
    computed: {
        products(){
            return this.$store.state.products.all.filter(item => {
                return item.productsType === 'left-sidebar'
            })
        }
    }*/
}
</script>