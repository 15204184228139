<template>
    <footer class="one-footer-area pt-100">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-logo">
                            <a href="#">
                                <img src="../../assets/img/logo-bk.png" alt="Logo">
                            </a>
                            <p>PCW LAW LIMITED</p>
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <!-- <div class="footer-item">
                        <div class="footer-quick">
                            <h3>Quick Links</h3>
                            <ul>
                                <li>
                                    <a href="#" target="_blank">Mortgage Calculator</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Mortgage Checklist</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Mortgage Glossary</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Renting vs Owning</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Credit Basics</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Disaster Relief Center</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Disclosure</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Home Disclosure Notice</a>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                <div class="col-sm-6 col-lg-3">
                    <!-- <div class="footer-item">
                        <div class="footer-quick">
                            <h3>About Us</h3>
                            <ul>
                                <li>
                                    <a href="#" target="_blank">Down Payment Options</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Your Mortgage Payment</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Appraisal Basics</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Contact</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Careers</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Privacy Choices</a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">Licencing</a>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-address">
                            <h3>Address</h3>
                            <ul>
                                <li>
                                    <i class='bx bx-phone-call'></i>
                                    <a href="tel:+64 9 929 1996">+64 9 929 1996</a>
                                </li>
                                <li>
                                    <i class='bx bx-phone-call'></i>
                                    <a href="tel:+64 9 535 5666">+64 9 535 5666</a>
                                </li>
                                <li>
                                    <i class='bx bx-message-square-detail'></i>
                                    <a href="mailto:info@pcwlaw.co.nz">info@pcwlaw.co.nz</a>
                                </li>
                                <li>
                                    <i class='bx bx-current-location' ></i>
                                    Unit I, 383 Khyber Pass Rd, Newmarket, Auckland 1023, NZ
                                </li>
                                <li>
                                    <i class='bx bx-current-location' ></i>
                                    1 Millhouse Dr, Northpark, Auckland 2013, NZ
                                    
                                </li>
                                <li>
                                    <i class='bx bx-current-location' ></i>
                                    PO Box 28011, Remuera, Auckland 1541, NZ
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-area">
                <!-- <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="copyright-item">
                            <ul>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/visa.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/master-card.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/paypal.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/skrill.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/master-card2.jpg" alt="Method">
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <img src="../../assets/img/home-one/visa2.jpg" alt="Method">
                                    </a>
                                </li>
                            </ul>
                        </div> 
                    </div> -->
                    <div class="col-lg-6">
                        <div class="copyright-item">
                            <p>&copy; {{currentYear}} Designed By <a href="" target="_blank">Lei Zhang</a></p>
                        </div>
                    </div>
                </div>
            </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>