<template>
    <nav :class="['navbar navbar-expand-md navbar-light', { 'is-sticky': isSticky }]">
        <router-link class="navbar-brand" to="/">
            <img src="../../assets/img/pcw_logo.png" alt="Logo">
        </router-link>

        <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ 'active': button_active_state }" v-on:click="button_active_state = !button_active_state">
            <i class='bx bx-menu'></i>
            <i class='bx bx-x'></i>
        </div>

        <div class="collapse navbar-collapse" :class="{ toggler: active }">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('home')" href="/">{{ $t('Menu-Home') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('about')" href="/">{{ $t('Menu-AboutUs') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('service')" href="/">{{ $t('Menu-OurServices') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('team')" href="/">{{ $t('Menu-Team') }}</a>
                </li>
                <!--                 <li class="nav-item">
                    <a class="nav-link" @click="scroll('blog')" href="javascript:void(0)">Blog</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" @click="scroll('contact')" href="/home/">{{ $t('Menu-Contact') }}</a>
                </li>
            </ul>
        </div>
        <LocaleSwitcher />
    </nav>


    <div id="team" class="staff-area pb-70">
        <div class="container">

            <br />
            <div class="col-lg-3 col-sm-6 float-start">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="../../assets/img/more-home/staff/PingChen.jpg" alt="image">
                    </div>

                </div>
            </div>

            <div class="staff-content">
                <div class="wrapper">
                    <div class="content">
                        <h1>{{ $t('StaffName-PingChen')}}</h1>

                        <p class="staff-intro">{{ $t('StaffPing-Intro')}}</p>
                    </div>
                </div>
            </div>
        </div>
        <br />


        <Footer />
    </div>
</template>


<script>
import NavbarOne from '../Layouts/NavbarOne'
import Footer from '../Layouts/FooterPCW'
import LocaleSwitcher from "../LocaleSwitcher.vue";


export default {
    name: 'StaffPing',
    components: {
        NavbarOne,
        Footer,
        LocaleSwitcher,
    }
}
</script>