<template>
    <div id="services" class="core-service-area ptb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">What We Offer</span>
                <h2>Our Core Service</h2>
            </div>

            <div class="core-service-list-tab">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="core-service-1-tab" data-bs-toggle="tab" href="#core-service-1" role="tab" aria-controls="core-service-1">Purchase New House</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-2-tab" data-bs-toggle="tab" href="#core-service-2" role="tab" aria-controls="core-service-2">Apply For Loan</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-3-tab" data-bs-toggle="tab" href="#core-service-3" role="tab" aria-controls="core-service-3">Refinancing Your Home</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-4-tab" data-bs-toggle="tab" href="#core-service-4" role="tab" aria-controls="core-service-4">Capital Management</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-5-tab" data-bs-toggle="tab" href="#core-service-5" role="tab" aria-controls="core-service-5">Renovation</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="core-service-6-tab" data-bs-toggle="tab" href="#core-service-6" role="tab" aria-controls="core-service-6">Refinance Advisor</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="core-service-1" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-building-house"></i>
                                    </div>
                                    <h3>Purchase New House</h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-1.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-2" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-dollar-circle"></i>
                                    </div>
                                    <h3>Apply For Loan</h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-2.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-3" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-home-circle"></i>
                                    </div>
                                    <h3>Refinancing Your Home</h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-3.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-4" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-hand-up"></i>
                                    </div>
                                    <h3>Capital Management</h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-4.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-5" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-wrench"></i>
                                    </div>
                                    <h3>Renovation House</h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-5.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="core-service-6" role="tabpanel">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-7 col-md-6">
                                <div class="core-service-content">
                                    <div class="icon">
                                        <i class="bx bxs-user-circle"></i>
                                    </div>
                                    <h3>Refinance Advisor</h3>
                                    <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                        <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="core-service-image">
                                    <img src="../../assets/img/more-home/core-service/core-service-6.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>