<template>
    <div id="about" class="about-style-area-with-full-width ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-style-full-content">
                        <span class="sub-title">About Us</span>
                        <h3>We Are Fully Dedicated To Support You</h3>
                        <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>

                        <div class="inner-content">
                            <h4>Support Service</h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        </div>
                        <div class="inner-content">
                            <h4>Refinance Guide</h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                        </div>
                        <div class="about-btn">
                            <a href="#" class="main-default-btn">Learn More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="about-full-image-wrap">
                                <img src="../../assets/img/more-home/about/about-3.jpg" alt="image">

                                <div class="counter-box">
                                    <h4>20+</h4>
                                    <span>Years Of Experience</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="about-full-image-wrap">
                                <img src="../../assets/img/more-home/about/about-4.jpg" alt="image">

                                <div class="counter-box">
                                    <h4>90%</h4>
                                    <span>Customer Worldwide</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>