<template>
    <div class="one-faq-area four-faq-area pb-70">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="faq-content">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>How Do You Qualify For A Loan?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>A Good Lender Will Clearly Explain Mortgage?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>Can You Get A Mortgage Without A Credit Score?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>How Much Home Can You Afford?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>How Do You Qualify For A Loan?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a>A Good Lender Will Clearly Explain Mortgage?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
                <div class="col-lg-6 pr-0">
                    <div class="faq-img-two">
                        <img src="../../assets/img/home-four/faq.jpg" alt="FAQ">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>