<template>
    <div class="beyond-style-area-with-bg ptb-100">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Our Step And Process</span>
                <h2>Go Beyond With Cavo</h2>
            </div>

            <div class="beyond-style-list-tab">
                <carousel 
                    :autoplay="5000"
                    :wrap-around="true"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="beyond-style-content">
                                    <h3>{{slide.title}}</h3>
                                    <p class="bold">{{slide.boldDesc}}</p>
                                    <p>{{slide.desc}}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="beyond-style-image">
                                    <img :src="slide.image" alt="image">
                                </div>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                        <navigation>
                            <template #next>
                                <i class='bx bx-right-arrow-alt'></i>
                            </template>
                            <template #prev>
                                <i class='bx bx-left-arrow-alt'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

export default defineComponent ({
    name: 'GoBeyond',
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                title: 'Appraisal',
                boldDesc: 'Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
                desc: 'pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.',
                image: require('../../assets/img/more-home/beyond/beyond-2.jpg'),
            },
            {
                id: 2,
                title: 'Appraisal',
                boldDesc: 'Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
                desc: 'pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.',
                image: require('../../assets/img/more-home/beyond/beyond-2.jpg'),
            },
            {
                id: 3,
                title: 'Appraisal',
                boldDesc: 'Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
                desc: 'pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.',
                image: require('../../assets/img/more-home/beyond/beyond-2.jpg'),
            },
            {
                id: 4,
                title: 'Appraisal',
                boldDesc: 'Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
                desc: 'pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.',
                image: require('../../assets/img/more-home/beyond/beyond-2.jpg'),
            },
            {
                id: 5,
                title: 'Appraisal',
                boldDesc: 'Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
                desc: 'pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.',
                image: require('../../assets/img/more-home/beyond/beyond-2.jpg'),
            },
            {
                id: 6,
                title: 'Appraisal',
                boldDesc: 'Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.',
                desc: 'pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.',
                image: require('../../assets/img/more-home/beyond/beyond-2.jpg'),
            },
        ],
    }),
})
</script>