<template>
    <div id="team" class="three-team-area ptb-100">
        <div class="container">
            <div class="one-section-title two-section-title">
                <span class="sub-title">TEAM & LEADERSHIP</span>
                <h2>Find A Loan Officer</h2>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-top">
                            <img src="../../assets/img/home-three/team1.jpg" alt="Team">
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="tel:123456789">
                                        <i class='bx bx-phone-call'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-bottom">
                            <i class='bx bx-folder'></i>
                            <h3>JAMES TAYLOR</h3>
                            <span>Branch Manager</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-top">
                            <img src="../../assets/img/home-three/team2.jpg" alt="Team">
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="tel:123456789">
                                        <i class='bx bx-phone-call'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-bottom">
                            <i class='bx bx-folder'></i>
                            <h3>AMANDA SILBER</h3>
                            <span>Account Manager</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-top">
                            <img src="../../assets/img/home-three/team3.jpg" alt="Team">
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="tel:123456789">
                                        <i class='bx bx-phone-call'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-bottom">
                            <i class='bx bx-folder'></i>
                            <h3>CHRIS GARCIA</h3>
                            <span>Loan Officer</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="team-item">
                        <div class="team-top">
                            <img src="../../assets/img/home-three/team4.jpg" alt="Team">
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="tel:123456789">
                                        <i class='bx bx-phone-call'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-bottom">
                            <i class='bx bx-folder'></i>
                            <h3>CHRIS M. SHELTON</h3>
                            <span>Regional Director</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team-call">
                <p><i class='bx bx-phone-call'></i> Call Now for any help! <a href="tel:(567)234-0543">(567) 234-0543</a></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamLeadership'
}
</script>