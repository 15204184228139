<template>
    <div class="pt-100 pb-70">
        <div class="container">
            <div class="main-section-title">
                <span class="sub-title">Categories Of Loan</span>
                <h2>Find A Own Loan</h2>
            </div>

            <div class="find-own-loan-list-tab">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="find-own-loan-1-tab" data-bs-toggle="tab" href="#find-own-loan-1" role="tab" aria-controls="find-own-loan-1">Purchase</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="find-own-loan-2-tab" data-bs-toggle="tab" href="#find-own-loan-2" role="tab" aria-controls="find-own-loan-2">Homebuyer</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="find-own-loan-3-tab" data-bs-toggle="tab" href="#find-own-loan-3" role="tab" aria-controls="find-own-loan-3">Refinance</a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="find-own-loan-1" role="tabpanel">
                        <div class="find-own-loan-slider">
                            <carousel 
                                :autoplay="5000"
                                :wrap-around="true"
                                :breakpoints="breakpoints"
                            >
                                <slide v-for="slide in carouselItems" :key="slide.id">
                                    <div class="find-own-loan-item">
                                        <div class="icon">
                                            <i :class="slide.icon"></i>
                                        </div>
                                        <div class="loan-content">
                                            <h3>{{slide.title}}</h3>
                                            <p>{{slide.desc}}</p>
                                        </div>
                                        <span class="tag">{{slide.tag}}</span>
                                    </div>
                                </slide>

                                <template #addons>
                                    <navigation>
                                        <template #next>
                                            <i class='bx bx-right-arrow-alt'></i>
                                        </template>
                                        <template #prev>
                                            <i class='bx bx-left-arrow-alt'></i>
                                        </template>
                                    </navigation>
                                </template>
                            </carousel>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="find-own-loan-2" role="tabpanel">
                        <div class="find-own-loan-slider">
                            <carousel 
                                :autoplay="5000"
                                :wrap-around="true"
                                :breakpoints="breakpoints"
                            >
                                <slide v-for="slide in carouselItems" :key="slide.id">
                                    <div class="find-own-loan-item">
                                        <div class="icon">
                                            <i :class="slide.icon"></i>
                                        </div>
                                        <div class="loan-content">
                                            <h3>{{slide.title}}</h3>
                                            <p>{{slide.desc}}</p>
                                        </div>
                                        <span class="tag">{{slide.tag}}</span>
                                    </div>
                                </slide>

                                <template #addons>
                                    <navigation>
                                        <template #next>
                                            <i class='bx bx-right-arrow-alt'></i>
                                        </template>
                                        <template #prev>
                                            <i class='bx bx-left-arrow-alt'></i>
                                        </template>
                                    </navigation>
                                </template>
                            </carousel>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="find-own-loan-3" role="tabpanel">
                        <div class="find-own-loan-slider">
                            <carousel 
                                :autoplay="5000"
                                :wrap-around="true"
                                :breakpoints="breakpoints"
                            >
                                <slide v-for="slide in carouselItems" :key="slide.id">
                                    <div class="find-own-loan-item">
                                        <div class="icon">
                                            <i :class="slide.icon"></i>
                                        </div>
                                        <div class="loan-content">
                                            <h3>{{slide.title}}</h3>
                                            <p>{{slide.desc}}</p>
                                        </div>
                                        <span class="tag">{{slide.tag}}</span>
                                    </div>
                                </slide>

                                <template #addons>
                                    <navigation>
                                        <template #next>
                                            <i class='bx bx-right-arrow-alt'></i>
                                        </template>
                                        <template #prev>
                                            <i class='bx bx-left-arrow-alt'></i>
                                        </template>
                                    </navigation>
                                </template>
                            </carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default defineComponent ({
    name: 'FindOwnLoan',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                icon: 'bx bxs-building-house',
                tag: 'Home Loan',
                title: 'A Conventional Home Loan',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
            {
                id: 2,
                icon: 'bx bx-bulb',
                tag: 'Homeready',
                title: 'Homeready Fannie Mortgage',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
            {
                id: 3,
                icon: 'bx bxs-badge-dollar',
                tag: 'Renovation Loans',
                title: 'Renovation Loans For Buyers',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
            {
                id: 4,
                icon: 'bx bx-money',
                tag: 'Purchase Loans',
                title: 'Down Payment Has Stalled',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
            {
                id: 5,
                icon: 'bx bxs-building-house',
                tag: 'Home Loan',
                title: 'A Conventional Home Loan',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
            {
                id: 6,
                icon: 'bx bx-bulb',
                tag: 'Homeready',
                title: 'Homeready Fannie Mortgage',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
            {
                id: 7,
                icon: 'bx bxs-badge-dollar',
                tag: 'Renovation Loans',
                title: 'Renovation Loans For Buyers',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
            {
                id: 8,
                icon: 'bx bx-money',
                tag: 'Purchase Loans',
                title: 'Down Payment Has Stalled',
                desc: 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.',
            },
        ],
        breakpoints: {
            0: {
				itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
			1200: {
                itemsToShow: 4,
                snapAlign: 'center',
			}
        },
    }),
})
</script>