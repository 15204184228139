<template>
    <div>
        <div id="home" class="confidence-home-area">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="confidence-home-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Buy A Home By Confidence</h1>
                            <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>
        
                            <ul class="confidence-home-btn">
                                <li>
                                    <a href="#" class="main-default-btn">Mortgage Expert</a>
                                </li>
                                <li>
                                    <a 
                                        href="javascript:void(0)" 
                                        class="video-btn"
                                        v-on:click="isPopupMethod(isPopup)"
                                    >
                                        <i class="bx bx-play"></i> 
                                        <span>Intro With Company</span>
                                    </a>
                                </li>
                            </ul>
                        </div> 
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="confidence-home-image">
                            <img src="../../assets/img/more-home/banner/confidence-home.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            console.log(this.isPopup)
            return this.isPopup = !isPopup
        }
    }
}
</script>