<template>
    <div class="one-subscribe-area five-subscribe-area">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="subscribe-wrap">
                        <h2>Do you want to get update what’s upcoming?</h2>

                        <form class="newsletter-form" data-toggle="validator">
                            <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL">
    
                            <button class="btn subscribe-btn" type="submit">
                                Subscribe now
                            </button>
                        </form>
                        <div class="sub-img">
                            <img src="../../assets/img/home-five/subscribe-img.png" alt="Subscribe">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Subscribe'
}
</script>